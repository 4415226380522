import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Cart_purchase_Btn.module.css';

const CartPurchaseBtn = (): React.ReactElement => {
  const navigate = useNavigate();

  return (
    <div className={styles.section}>
      <div className={styles.Btn} onClick={() => navigate('/cart')}>
        장바구니 담기
      </div>
      <div className={styles.Btn} onClick={() => navigate('/order')}>
        바로 구매하기
      </div>
    </div>
  )

}

export default CartPurchaseBtn;