import React, { useState } from 'react';
import { IquestionDataItem } from '../../pages/Diagnosis_main/Diagnosis_main';
import styles from './Diagnosis_questionItem.module.css';


interface IDiagnosisQuestionItem {
  questionItmeData: IquestionDataItem
  setProgressState: React.Dispatch<React.SetStateAction<number>>
  setSkinScore: React.Dispatch<React.SetStateAction<number>>
}


const DiagnosisQuestionItem = (
  { questionItmeData,
    setProgressState,
    setSkinScore
  }: IDiagnosisQuestionItem
): React.ReactElement => {

  const [selectedOption, setSelectedOption] = useState<string | undefined>('')
  const [optionA, setOptionA] = useState(false)
  const [optionB, setOptionB] = useState(false)
  const [optionC, setOptionC] = useState(false)
  const [optionD, setOptionD] = useState(false)

  const calculateSkinScore = (option: string | undefined) => {
    const scoreA = (100 * (questionItmeData.point));
    const scoreB = (70 * (questionItmeData.point));
    const scoreC = (35 * (questionItmeData.point));
    const scoreD = (10 * (questionItmeData.point));

    if (option === "A" && !optionA) {
      optionB && setSkinScore(prev => prev - scoreB)
      optionC && setSkinScore(prev => prev - scoreC)
      optionD && setSkinScore(prev => prev - scoreD)
      setSkinScore(prev => prev + scoreA)
      setOptionB(false)
      setOptionC(false)
      setOptionD(false)
      setOptionA(true)
    }

    if (option === "B" && !optionB) {
      optionA && setSkinScore(prev => prev - scoreA)
      optionC && setSkinScore(prev => prev - scoreC)
      optionD && setSkinScore(prev => prev - scoreD)
      setSkinScore(prev => prev + scoreB)
      setOptionA(false)
      setOptionC(false)
      setOptionD(false)
      setOptionB(true)
    }
    if (option === "C" && !optionC) {
      optionA && setSkinScore(prev => prev - scoreA)
      optionB && setSkinScore(prev => prev - scoreB)
      optionD && setSkinScore(prev => prev - scoreD)
      setSkinScore(prev => prev + scoreC)
      setOptionA(false)
      setOptionB(false)
      setOptionD(false)
      setOptionC(true)
    }
    if (option === "D" && !optionD) {
      optionA && setSkinScore(prev => prev - scoreA)
      optionB && setSkinScore(prev => prev - scoreB)
      optionC && setSkinScore(prev => prev - scoreC)
      setSkinScore(prev => prev + scoreD)
      setOptionA(false)
      setOptionB(false)
      setOptionC(false)
      setOptionD(true)
    }
  }

  const handleSelect = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    //progress update
    selectedOption === '' && setProgressState((prev) => prev + (1 / 51) * 100)
    //score update
    const target = e.target as HTMLDivElement;
    setSelectedOption(target.dataset.option);
    calculateSkinScore(target.dataset.option);
  }

  return (
    <div className={styles.section}>
      <h3 className={styles.prompt}>
        <span className={styles.index}>Q{questionItmeData.id}.</span>
        {questionItmeData.prompt}</h3>
      <div className={styles.optionCnt}>
        <div className={`${styles.option} ${selectedOption === 'A' ? styles.selected : styles.unselected}`}
          data-option='A' onClick={(e) => handleSelect(e)}>
          {questionItmeData.optionA}</div>
        <div className={`${styles.option} ${selectedOption === 'B' ? styles.selected : styles.unselected}`}
          data-option='B' onClick={(e) => handleSelect(e)}>
          {questionItmeData.optionB}</div>
        <div className={`${styles.option} ${selectedOption === 'C' ? styles.selected : styles.unselected}`}
          data-option='C' onClick={(e) => handleSelect(e)}>
          {questionItmeData.optionC}</div>
        <div className={`${styles.option} ${selectedOption === 'D' ? styles.selected : styles.unselected}`}
          data-option='D' onClick={(e) => handleSelect(e)}>
          {questionItmeData.optionD}</div>
      </div>
    </div>
  )
}

export default DiagnosisQuestionItem;