
export function generateOMRType(
  agingScore: number,
  dehydrationScore: number,
  sensitivityScore: number,
  elasticityScore: number,
  breakoutScore: number,
  deadskinScore : number
  ) {

  const oilScore = (dehydrationScore + deadskinScore) * 0.5
  const notOliScore = 100 - (dehydrationScore + deadskinScore) * 0.5
  const moistScore = dehydrationScore
  const dryScore = 100 - dehydrationScore
  const sensiScore = 100 - (sensitivityScore)
  const resisScore = sensitivityScore


  if (oilScore > 50 && moistScore > 50) {
    if (resisScore > 50) {
      return {
        skinType: 'Oily,Moistured,Resistant',
        skinTypeSymbol: 'OMR',
        oilScore,
        notOliScore,
        moistScore,
        dryScore,
        sensiScore,
        resisScore,
        desc: `번들거림이 관찰되고
        여드름이 잘 나타날 수 있는 지성피부로 모공케어에 신경을 써야해요. 하지만 피부장벽과 수분 부족에 대한 걱정은 없는 아주 건강한 피부의 소유자에요.`}
    }
    return {
      skinType: 'Oily,Moistured,Sensitive',
      skinTypeSymbol: 'OMS',
      oilScore,
      notOliScore,
      moistScore,
      dryScore,
      sensiScore,
      resisScore,
      desc: `번들거림이 관찰되고
      여드름이 잘 나타날 수 있어 모공케어에 신경을 써야해요. 또한 약해진 피부장벽 때문에 외부자극에 민감한 피부를 가지고 있어요.`}
  }
  else if (notOliScore > 50 && moistScore > 50) {
    if (resisScore > 50) {
      return {
        skinType: 'Not-Oily,Moistured,Resistant',
        skinTypeSymbol: 'NMR',
        oilScore,
        notOliScore,
        moistScore,
        dryScore,
        sensiScore,
        resisScore,
        desc: `수분손실을 막아주는 피부 바깥층의 유분감소로 건조해진 건성피부에요. 
        하지만 피부장벽에 대한 걱정은 없는 아주 건강한 피부의 소유자에요.`}
    }
    return {
      skinType: 'Not-Oily,Moistured,Sensitive',
      skinTypeSymbol: 'NMS',
      oilScore,
      notOliScore,
      moistScore,
      dryScore,
      sensiScore,
      resisScore,
      desc: `수분손실을 막아주는 피부 바깥층의 유분감소로 건조해진 건성피부에요. 
      또한 약해진 피부장벽 때문에 외부자극에 민감한 피부를 가지고 있어요.`}
  }
  else if (oilScore > 50 && dryScore > 50) {
    if (resisScore > 50) {
      return {
        skinType: 'Oily,Dry,Resistant',
        skinTypeSymbol: 'ODR',
        oilScore,
        notOliScore,
        moistScore,
        dryScore,
        sensiScore,
        resisScore,
        desc: `번들거림이 관찰되고 여드름이 잘 나타날 수 있는 지성피부로 모공케어에 신경을 써야해요. 
        또한 수분이 부족한 피부이기 때문에 속건조가 나타날 수 있어요.`}
    }
    return {
      skinType: 'Oily,Dry,Sensitive',
      skinTypeSymbol: 'ODS',
      oilScore,
      notOliScore,
      moistScore,
      dryScore,
      sensiScore,
      resisScore,
      desc: `번들거림이 관찰되고 여드름이 잘 나타날 수 있는 지성피부로 모공케어에 신경을 써야해요. 
      또한 수분이 부족한 피부이기 때문에 속건조가 나타날 수 있고, 약해진 피부장벽 때문에 외부자극에 민감한 피부를 가지고 있어요.`}
  }
  else if (notOliScore > 50 && dryScore > 50) {
    if (resisScore > 50) {
      return {
        skinType: 'Not-Oily,Dry,Resistant',
        skinTypeSymbol: 'NDR',
        oilScore,
        notOliScore,
        moistScore,
        dryScore,
        sensiScore,
        resisScore,
        desc: `수분손실을 막아주는 피부 바깥층의 유분감소로 건조해진 건성피부에요. 
        또한 수분이 부족한 피부이기 때문에 속건조가 나타날 수 있어 특별히 보습과 수분차단에 신경써야 하는 피부에요.`}
    }
    return {
      skinType: 'Not-Oily,Dry,Resistant',
      skinTypeSymbol: 'NDS',
      oilScore,
      notOliScore,
      moistScore,
      dryScore,
      sensiScore,
      resisScore,
      desc: `수분손실을 막아주는 피부 바깥층의 유분감소로 건조해진 건성피부에요. 
      또한 수분이 부족한 피부이기 때문에 속건조가 나타날 수 있고 약해진 피부장벽 때문에 외부자극에 민감한 피부를 가지고 있어요.`}
  } 
  else {
    return {
      skinType: 'ERROR',
      skinTypeSymbol: 'ERR',
      oilScore,
      notOliScore,
      moistScore,
      dryScore,
      sensiScore,
      resisScore,
      desc: `error!!`}
  }
}