export const Questions =
{
    "elasticity": [
        {
            id: 1,
            type: 0,
            point: 0.29,
            prompt: `거울을 봤을때 나의 주름 상태는 어떻습니까?`,
            optionA: "없거나 양호한편이다",
            optionB: "잔주름이 있다",
            optionC: "눈가,팔자주름이 신경쓰인다",
            optionD: "전체적으로 주름이 많다",
        },
        {
            id: 2,
            type: 0,
            point: 0.14,
            prompt: "메이크업을 끝낸 뒤 2~3시간 후 주름에 메이크업 제품이 끼어있습니까?",
            optionA: "전혀 그렇지 않음",
            optionB: "메이크업을 하지 않는다",
            optionC: "약간 그런 편이다",
            optionD: "항상 그런 편이다",
        },
        {
            id: 3,
            type: 0,
            point: 0.19,
            prompt: "나는 얼마나 나이들어보인다고 생각합니까?",
            optionA: "내 연령대 보다 젊어보인다",
            optionB: "내 연령대로 보인다",
            optionC: "내 연령대 보다 조금 늙어 보인다",
            optionD: "내 연령대 보다 많이 늙어보인다",
        },
        {
            id: 4,
            type: 0,
            point: 0.29,
            prompt: "아침에 일어났을 때 얼굴에 베개등의 자국이 얼마나 오랫동안 남아있나요? ",
            optionA: "없거나 잘모르겠다",
            optionB: "세안후 없어진다",
            optionC: "세안후 일정시간 남아있다",
            optionD: "반나절 이상지속된다",
        },
        {
            id: 5,
            type: 0,
            point: 0.09,
            prompt: "평소에 얼마나 많이 담배를 피우시나요?",
            optionA: "피우지 않는다",
            optionB: "과거에는 피웠지만 현재는 피우지 않는다",
            optionC: "하루 반갑",
            optionD: "하루 한갑 이상",
        },
    ],
    "breakout": [
        {
            id: 1,
            type: 1,
            point: 0.20,
            prompt: "화장품 교체시 트러블(여드름, 뾰루지)이 발생한적이 있습니까?",
            optionA: "없거나 모르겠다",
            optionB: "가끔 발생한다",
            optionC: "자주 발생한다",
            optionD: "항상 발생한다",
        },
        {
            id: 2,
            type: 1,
            point: 0.15,
            prompt: "사진을 찍으면 얼굴이 붉게 보입니까?",
            optionA: "전혀 아니다",
            optionB: "때때로 그렇다",
            optionC: "자주 그렇다",
            optionD: "항상 그렇다",
        },
        {
            id: 3,
            type: 1,
            point: 0.4,
            prompt: "화장품을 사용하면 얼굴에 가려움증 혹은 따끔거리는 증상 등이 나타난 경험이 있습니까? ",
            optionA: "없거나 모르겠다",
            optionB: "때때로 그렇다",
            optionC: "자주 그렇다",
            optionD: "항상 그렇다",
        },
        {
            id: 4,
            type: 1,
            point: 0.1,
            prompt: "외부 환경(날씨, 온도변화,스트레스)에 따라 피부가 붉게 변하나요?",
            optionA: "없거나 모르겠다",
            optionB: "때때로 그렇다",
            optionC: "자주 그렇다",
            optionD: "항상 그렇다",
        },
        {
            id: 5,
            type: 1,
            point: 0.15,
            prompt: "얼굴 부위 중 트러블이 자주 생기는 부위는?",
            optionA: "없다",
            optionB: "이마,코 주변",
            optionC: "볼",
            optionD: "입,턱 주변",
        },
    ],
    "sensitivity": [
        {
            id: 1,
            type: 2,
            point: 0.22,
            prompt: "14K 금이 아닌 악세사리를 착용하면 피부에 발진이 자주 발생하나요?",
            optionA: "없거나 모르겠다",
            optionB: "거의 안생긴다",
            optionC: "자주 생긴다",
            optionD: "항상 생긴다",
        },
        {
            id: 2,
            type: 2,
            point: 0.12,
            prompt: "스테로이드 성분(연고,주사,약) 이 함유된 제품을 사용한 적이 있나요?",
            optionA: "사용한 적 없다",
            optionB: "잘 모르겠다",
            optionC: "예전에 사용하였으나 현재는 사용하지 않는다",
            optionD: "사용중이다",
        },
        {
            id: 3,
            type: 2,
            point: 0.32,
            prompt: "평소 당신의 피부가 예민하다고 생각하나요?",
            optionA: "예민하지 않다",
            optionB: "잘 모르겠다",
            optionC: "약간 예민하다",
            optionD: "매우 예민하다",
        },
        {
            id: 4,
            type: 2,
            point: 0.12,
            prompt: "평소 당신의 피부가 얇다고 생각하나요?",
            optionA: "두꺼운 편이다",
            optionB: "잘 모르겠다",
            optionC: "보통이다",
            optionD: "얇은 편이다",
        },
        {
            id: 5,
            type: 2,
            point: 0.22,
            prompt: "평소 얼굴에 각질이 많은 편인가요?",
            optionA: "없다",
            optionB: "가끔 있다",
            optionC: "종종 있다",
            optionD: "항상 있다",
        },
    ],
    "aging": [
        {
            id: 1,
            type: 3,
            point: 0.36,
            prompt: "하루에 자외선차단 제품(선크림, 메이크업 쿠션 등)을 몇 번 바릅니까?",
            optionA: "수시로 덧바른다",
            optionB: "외출할 때마다 바른다",
            optionC: "여름철에만 바른다",
            optionD: "전혀 바르지 않는다",
        },
        {
            id: 2,
            type: 3,
            point: 0.21,
            prompt: "햇볕에 나가면 기미 또는 주근깨가 생기나요??",
            optionA: "전혀 생기지 않는다.",
            optionB: "아주 가끔 생긴다",
            optionC: "피부가 어두워서 잘 모르겠다",
            optionD: "자주 생긴다",
        },
        {
            id: 3,
            type: 3,
            point: 0.21,
            prompt: "일상생활을 기준으로, 일상생활 중에서 햇빛에 노출 되는 시간은 하루에 얼마나 됩니까?",
            optionA: "1시간 미만",
            optionB: "1시간~3시간",
            optionC: "3시간~5시간",
            optionD: "5시간 이상",
        },
        {
            id: 4,
            type: 3,
            point: 0.11,
            prompt: "햇볕이 있는 야외활동(등산, 캠핑, 낚시,산책 등)을 하는 빈도가 얼마나 되나요?",
            optionA: "거의 없음",
            optionB: "월 1~2회 이내",
            optionC: "월 4회 이내",
            optionD: "매주 1회 이상 ",
        },
        {
            id: 5,
            type: 3,
            point: 0.11,
            prompt: "향후 1~2개월이내 장시간 빈도의 야외활동 계획이 있습니까?",
            optionA: "없다",
            optionB: "잘 모르겠다",
            optionC: "생각 중이다",
            optionD: "계획 중이다",
        },
    ],
    "dehydration": [
        {
            id: 1,
            type: 4,
            point: 0.15,
            prompt: "하루에 스킨, 로션 등(보습제 계열)을 몇 번 바릅니까?",
            optionA: "3회 이상",
            optionB: "2회",
            optionC: "1회",
            optionD: "안 바름",
        },
        {
            id: 2,
            type: 4,
            point: 0.3,
            prompt: "건조한 환경에서 화장품을 사용하지 않으면, 당신의 얼굴 피부는 어떻습니까?",
            optionA: "아무 이상없다",
            optionB: "정상적인 느낌이나 약간 당기는것같다.",
            optionC: "당기는 느낌과 건조함이 약간 든다",
            optionD: "매우 건조하고 갈라지는 느낌이 든다.",
        },
        {
            id: 3,
            type: 4,
            point: 0.15,
            prompt: "평소 주류 습관은 어떻게 되시나요?",
            optionA: "마시지 않는다",
            optionB: "거의 마시지 않는다",
            optionC: "월 4회 이내",
            optionD: "주 1회 이상",
        },
        {
            id: 4,
            type: 4,
            point: 0.2,
            prompt: "평소 건조함과 유분감이 계절에 따라 영향을 받나요?",
            optionA: "영향이 없다",
            optionB: "가끔식 받는다",
            optionC: "자주 받는다",
            optionD: "항상 받는다",
        },
        {
            id: 5,
            type: 4,
            point: 0.2,
            prompt: "평소 세안 후 화장품을 바른 후에도 속당김을 느끼나요?",
            optionA: "속당김이 없다",
            optionB: "세안 후 가끔식 있다",
            optionC: "세안후 자주 있다",
            optionD: "세안후 항상 있다",
        },
    ],
    "odor": [
        {
            id: 1,
            type: 5,
            point: 0.06,
            prompt: "평소 배변활동을 활발하게 합니까?",
            optionA: "주 7회 이상",
            optionB: "주 4회 이하",
            optionC: "주 3회 이하",
            optionD: "주 2회 이하 변비가 있다",
        },
        {
            id: 2,
            type: 5,
            point: 0.21,
            prompt: "아침에 머리를 감으면, 오후에 두피에서 냄새가 올라옵니까?",
            optionA: "냄새가 나지 않는다",
            optionB: "냄새가 가끔 난다",
            optionC: "냄새가 자주 난다",
            optionD: "냄새가 항상 난다",
        },
        {
            id: 3,
            type: 5,
            point: 0.21,
            prompt: "마스크를 끼고 생활하면서 구취가 난다고 느낀 적이 있습니까?",
            optionA: "구취가 나지 않는다",
            optionB: "구취가 가끔 느껴진다",
            optionC: "구취가 자주 느껴진다",
            optionD: "항상 구취가 느껴진다",
        },
        {
            id: 4,
            type: 5,
            point: 0.02,
            prompt: "본인의 피부타입",
            optionA: "복합성",
            optionB: "지성",
            optionC: "건성",
            optionD: "민감성",
        },
        {
            id: 5,
            type: 5,
            point: 0.5,
            prompt: "씻고 난후 본인의 피부에서 냄새가 납니까?",
            optionA: "전혀 안난다",
            optionB: "가끔 그렇다",
            optionC: "자주 그렇다",
            optionD: "항상 그렇다",
        },
    ],
    "darkness": [
        {
            id: 1,
            type: 6,
            point: 0.31,
            prompt: "현재의 피부 색깔은 어떻습니까?",
            optionA: "하얀편",
            optionB: "노란편",
            optionC: "붉은편",
            optionD: "어둡고 칙칙한편",
        },
        {
            id: 2,
            type: 6,
            point: 0.21,
            prompt: "현재 얼굴에 기미, 주근깨 등 색소침착 상태가 어떻습니까?",
            optionA: "색소침착이 없다",
            optionB: "색소침착이 조금있다",
            optionC: "색소침착이 군데군데 있다",
            optionD: "색소침착이 얼굴 전체적으로 많다",
        },
        {
            id: 3,
            type: 6,
            point: 0.21,
            prompt: "피부톤이 전체적으로 어둡고 칙칙해 보이나요?",
            optionA: "그렇지 않다",
            optionB: "보통인 것 같다",
            optionC: "부분적으로 그렇다",
            optionD: "전체적으로 그렇다",
        },
        {
            id: 4,
            type: 6,
            point: 0.21,
            prompt: "햇빛에 장시간 노출되면 얼굴과 몸의 피부 상태는 어떻게 되나요?",
            optionA: "변화없다",
            optionB: "잘 모르겠다",
            optionC: "피부색이 짙어진다",
            optionD: "피부색이 붉어진다",
        },
        {
            id: 5,
            type: 6,
            point: 0.06,
            prompt: "평소 비타민C를 함유한 영양제나 음식을 자주 섭취하십니까?",
            optionA: "꾸준히 섭취한다",
            optionB: "가끔 섭취한다",
            optionC: "잘 모르겠다",
            optionD: "먹지 않는다",
        },
    ],
    "deadSkin": [
        {
            id: 1,
            type: 7,
            point: 0.05,
            prompt: "바디 스크럽(스크럽 타올 포함)은 일주일에 몇번 사용합니까?",
            optionA: "없다",
            optionB: "가끔 사용한다",
            optionC: "자주 사용한다",
            optionD: "매일 사용한다",
        },
        {
            id: 2,
            type: 7,
            point: 0.3,
            prompt: "평소 얼굴 각질 관리를 일주일에 몇번 하나요?",
            optionA: "각질 없음",
            optionB: "일주일에 1회 이상",
            optionC: "한달에 1~2회",
            optionD: "각질은 있으나 관리하지 않음",
        },
        {
            id: 3,
            type: 7,
            point: 0.15,
            prompt: "메이크업을 하면 화장이 잘 뜨는 편이다",
            optionA: "화장을 하지 않는다",
            optionB: "거의 그렇지 않다",
            optionC: "가끔 그런 편이다",
            optionD: "항상 그런 편이다",
        },
        {
            id: 4,
            type: 7,
            point: 0.35,
            prompt: "계절에 따라 각질이 많이 생기는 편인가요?",
            optionA: "각질이 잘 안생긴다",
            optionB: "가끔 각질이 생긴다",
            optionC: "봄/가을 환절기에 많이 생긴다",
            optionD: "계절에 상관없이 많이 생긴다",
        },
        {
            id: 5,
            type: 7,
            point: 0.15,
            prompt: "기초 화장 또는 메이크업시 피부가 밀리는것같은 느낌이 드는가?",
            optionA: "화장을 하지 않는다",
            optionB: "거의 그렇지 않다",
            optionC: "가끔 그렇다",
            optionD: "항상 그렇다",
        },
    ],
    "recovery": [
        {
            id: 1,
            type: 8,
            point: 0.07,
            prompt: "일정한 시간에 취침합니까?",
            optionA: "항상 그렇다",
            optionB: "가끔 그렇다",
            optionC: "항상 바뀐다",
            optionD: "밤낮이 바뀌었다",
        },
        {
            id: 2,
            type: 8,
            point: 0.07,
            prompt: "일일 평균 수면시간은 몇 시간입니까?",
            optionA: "10시간이상",
            optionB: "7~10 시간",
            optionC: "5~7 시간",
            optionD: "5시간 미만",
        },
        {
            id: 3,
            type: 8,
            point: 0.42,
            prompt: "얼굴에 여드름 외 피부트러블이 있습니까?",
            optionA: "없다",
            optionB: "과거에 있었지만 현재는 없다",
            optionC: "가끔 생긴다",
            optionD: "트러블이 많다",
        },
        {
            id: 4,
            type: 8,
            point: 0.22,
            prompt: "여드름이나 외상 후에 생기는 갈색(분홍색은 아님)의 상처 자국이 얼마나 오래 남는가?",
            optionA: "전혀 남지 않음",
            optionB: "1주 이내 사라진다",
            optionC: "몇 주 후 사라진다",
            optionD: "여러달 남아있다",
        },
        {
            id: 5,
            type: 8,
            point: 0.22,
            prompt: "햇빛에 노출되었을 시 피부가 금방 붉고 따가워 집니까?",
            optionA: "전혀 없다",
            optionB: "잘 모르겠다",
            optionC: "가끔 그렇다",
            optionD: "종종 그렇다",
        },
    ],
    "itchy": [
        {
            id: 1,
            type: 9,
            point: 0.15,
            prompt: "피부 아토피가 있습니까?",
            optionA: "없다",
            optionB: "과거에 있었지만 현재는 없다",
            optionC: "약은 사용하지 않지만 경미한 증상이 있다",
            optionD: "약을 처방받아 사용중이다",
        },
        {
            id: 2,
            type: 9,
            point: 0.25,
            prompt: "건조함으로 인해 피부 가려움을 느낄 때가 있나요?",
            optionA: "없다",
            optionB: "가끔 그렇다",
            optionC: "계절이 바뀔 때 그렇다",
            optionD: "항상 그렇다",
        },
        {
            id: 3,
            type: 9,
            point: 0.15,
            prompt: "미세먼지 등 외부 요인으로 인한 피부 가려움증을 느낀 적이 있습니까?",
            optionA: "없다",
            optionB: "가끔 그렇다",
            optionC: "자주 그렇다",
            optionD: "항상 그렇다",
        },
        {
            id: 4,
            type: 9,
            point: 0.3,
            prompt: "화장품 사용시 가려움을 느낀적이 있습니까?",
            optionA: "없다",
            optionB: "가끔 그렇다",
            optionC: "자주 그렇다",
            optionD: "항상 그렇다",
        },
        {
            id: 5,
            type: 9,
            point: 0.15,
            prompt: "세안 후 화장품을 바르기전 가려움을 느낀적이 있습니까?",
            optionA: "없다",
            optionB: "가끔 그렇다",
            optionC: "자주 그렇다",
            optionD: "항상 그렇다",
        },
    ],
    "base": [
        {
            id: 1,
            type: 10,
            point: 1,
            prompt: "세안 후 몇 단계에 걸쳐 기초화장을 하나요?",
            optionA: "1단계(예. 스킨, 올인원 등 1가지 제품)",
            optionB: "2단계(예. 스킨-로션, 2가지 제품)",
            optionC: "3단계(예. 스킨-로션-크림, 3가지 제품)",
            optionD: "4단계 이상(예. 스킨-에센스-로션-크림",
        },
    ]
}

