import React, { useState } from 'react';
import { generateDiagnosisResultAdvise } from '../../api/diagnosis_advise';
import { IskinConditionScore } from '../Diagnosis_result/Diagnosis_result';
import styles from './Diagnosis_result_advise.module.css';

interface IresultAdvise {
  skinConditionScore: IskinConditionScore
}

interface IresultAdviseInfo {
  skinConditionScore: number
  skinCondition: { korName: string, engName: string }
}


const DiagnosisResultAdvise = (
  { skinConditionScore }: IresultAdvise
): React.ReactElement => {

  const [conditionState, setConditionState] = useState<string | undefined>('elasticity');


  const skinCondition = {
    elasticity: { korName: '피부탄력', engName: 'elasticity' },
    breakout: { korName: '피부트러블', engName: 'breakout' },
    sensitivity: { korName: '민감성', engName: 'sensitivity' },
    aging: { korName: '노화도', engName: 'aging' },
    dehydration: { korName: '수분부족', engName: 'dehydration' },
    odor: { korName: '체취', engName: 'odor' },
    darkness: { korName: '색소침착', engName: 'darkness' },
    deadSkin: { korName: '각질', engName: 'deadSkin' },
    recovery: { korName: '피부재생력', engName: 'recovery' },
    itchy: { korName: '가려움', engName: 'itchy' },
  }

  const handleSelectCondition = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const target = e.target as HTMLDivElement;
    setConditionState(target.dataset.category);
  }

  return (
    <div className={styles.section}>
      <h3 className={styles.category}>피부 가이드라인</h3>
      <h2 className={styles.mainTitle}>홍길동님 이렇게 관리해보세요!</h2>
      <div className={styles.conditionCategoryCnt}>
        <button className={`${styles.conditionBtn} ${conditionState === skinCondition.elasticity.engName ? styles.selected : ''}`}
          data-category={skinCondition.elasticity.engName}
          onClick={(e) => handleSelectCondition(e)}>
          {skinCondition.elasticity.korName}</button>

        <button className={`${styles.conditionBtn} ${conditionState === skinCondition.breakout.engName ? styles.selected : ''}`}
          data-category={skinCondition.breakout.engName}
          onClick={(e) => handleSelectCondition(e)}>
          {skinCondition.breakout.korName}</button>

        <button className={`${styles.conditionBtn} ${conditionState === skinCondition.sensitivity.engName ? styles.selected : ''}`}
          data-category={skinCondition.sensitivity.engName}
          onClick={(e) => handleSelectCondition(e)}>
          {skinCondition.sensitivity.korName}</button>

        <button className={`${styles.conditionBtn} ${conditionState === skinCondition.aging.engName ? styles.selected : ''}`}
          data-category={skinCondition.aging.engName}
          onClick={(e) => handleSelectCondition(e)}>
          {skinCondition.aging.korName}</button>

        <button className={`${styles.conditionBtn} ${conditionState === skinCondition.dehydration.engName ? styles.selected : ''}`}
          data-category={skinCondition.dehydration.engName}
          onClick={(e) => handleSelectCondition(e)}>
          {skinCondition.dehydration.korName}</button>

        <button className={`${styles.conditionBtn} ${conditionState === skinCondition.odor.engName ? styles.selected : ''}`}
          data-category={skinCondition.odor.engName}
          onClick={(e) => handleSelectCondition(e)}>
          {skinCondition.odor.korName}</button>

        <button className={`${styles.conditionBtn} ${conditionState === skinCondition.darkness.engName ? styles.selected : ''}`}
          data-category={skinCondition.darkness.engName}
          onClick={(e) => handleSelectCondition(e)}>
          {skinCondition.darkness.korName}</button>

        <button className={`${styles.conditionBtn} ${conditionState === skinCondition.deadSkin.engName ? styles.selected : ''}`}
          data-category={skinCondition.deadSkin.engName}
          onClick={(e) => handleSelectCondition(e)}>
          {skinCondition.deadSkin.korName}</button>

        <button className={`${styles.conditionBtn} ${conditionState === skinCondition.recovery.engName ? styles.selected : ''}`}
          data-category={skinCondition.recovery.engName}
          onClick={(e) => handleSelectCondition(e)}>
          {skinCondition.recovery.korName}</button>

        <button className={`${styles.conditionBtn} ${conditionState === skinCondition.itchy.engName ? styles.selected : ''}`}
          data-category={skinCondition.itchy.engName}
          onClick={(e) => handleSelectCondition(e)}>
          {skinCondition.itchy.korName}</button>
      </div>

      {conditionState === 'elasticity' &&
        <DiagnosisResultAdviseInfo
          skinConditionScore={skinConditionScore.elasticityScore}
          skinCondition={skinCondition.elasticity} />
      }
      {conditionState === 'breakout' &&
        <DiagnosisResultAdviseInfo
          skinConditionScore={skinConditionScore.breakoutScore}
          skinCondition={skinCondition.breakout} />}

      {conditionState === 'sensitivity' &&
        <DiagnosisResultAdviseInfo
          skinConditionScore={skinConditionScore.sensitivityScore}
          skinCondition={skinCondition.sensitivity}
        />}

      {conditionState === 'aging' &&
        <DiagnosisResultAdviseInfo
          skinConditionScore={skinConditionScore.agingScore}
          skinCondition={skinCondition.aging}
        />}

      {conditionState === 'dehydration' &&
        <DiagnosisResultAdviseInfo
          skinConditionScore={skinConditionScore.dehydrationScore}
          skinCondition={skinCondition.dehydration}
        />}

      {conditionState === 'odor' &&
        <DiagnosisResultAdviseInfo
          skinConditionScore={skinConditionScore.odorScore}
          skinCondition={skinCondition.odor}
        />}

      {conditionState === 'darkness' &&
        <DiagnosisResultAdviseInfo
          skinConditionScore={skinConditionScore.darknessScore}
          skinCondition={skinCondition.darkness}
        />}

      {conditionState === 'deadSkin' &&
        <DiagnosisResultAdviseInfo
          skinConditionScore={skinConditionScore.deadSkinScore}
          skinCondition={skinCondition.deadSkin}
        />}

      {conditionState === 'recovery' &&
        <DiagnosisResultAdviseInfo
          skinConditionScore={skinConditionScore.recoveryScore}
          skinCondition={skinCondition.recovery}
        />}

      {conditionState === 'itchy' &&
        <DiagnosisResultAdviseInfo
          skinConditionScore={skinConditionScore.itchyScore}
          skinCondition={skinCondition.itchy}
        />}

    </div>
  )
}

export default DiagnosisResultAdvise;

const DiagnosisResultAdviseInfo = (
  { skinConditionScore, skinCondition }: IresultAdviseInfo
): React.ReactElement => {

  return (
    <div className={styles.adviseCnt}>
      <p className={styles.adviseDesc}>
        <h3>✔︎ {skinCondition.korName}</h3>
        {generateDiagnosisResultAdvise(skinConditionScore, `${skinCondition.engName}`)}
      </p>
    </div>
  )
}