import React from 'react';
import { ItypeInfo } from '../Diagnosis_result_type/Diagnosis_result_type';
import styles from './Diagnosis_result_typeIcon.module.css';


interface ItypeIcon {
  typeInfo: ItypeInfo
}

const DiagnosisResultTypeIcon = (
  { typeInfo }: ItypeIcon
): React.ReactElement => {

  return (
    <div className={styles.typeIcon}>
      <div className={styles.background}>
        <img src={typeInfo.iconURL} alt='skinIcon' className={styles.icon}></img>
        <div className={styles.textCnt}>
          <h1>{typeInfo.typeName.charAt(0)}</h1>
          <h3>{typeInfo.typeName.substr(1)}</h3>
        </div>
      </div>
    </div>
  )
}

export default DiagnosisResultTypeIcon; 