import React from 'react';
import { IskinConditionInfo } from '../Diagnosis_result_condition/Diagnosis_result_condition';
import styles from './Diagnosis_result_conditionScore.module.css';

interface IresultConditionScore {
  skinScore: number
  skinConditionInfo: IskinConditionInfo
}

const DiagnosisResultConditionScore = (
  { skinScore, skinConditionInfo }: IresultConditionScore
): React.ReactElement => {

  return (
    <div className={styles.section}>
      <div className={styles.container}>
        <div className={styles.leftCnt}>
          <img src={skinConditionInfo.iconURl} alt='skin_condition'
            className={styles.icon} />
          <h4>{skinConditionInfo.conditionName}</h4>
        </div>
        <div className={styles.rightCnt}>
          <h4 className={styles.score}>{skinScore}점</h4>
          <h4 className={styles.state}>{generateSkinConditionState(skinScore)}</h4>
        </div>
      </div>
      <div className={styles.barCnt}>
        <div className={styles.barHigh}>
          <div className={styles.barMedium}>
            <div className={styles.barRegular}>
              <span className={styles.pointer} style={{ left: `${skinScore}%` }}>▲</span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.infoBox}>
        <h5>어떤 성분으로 관리해야 할까요?</h5>
      </div>
    </div>
  )
}

export default DiagnosisResultConditionScore;

function generateSkinConditionState(skinConditionScore: number) {
  if (skinConditionScore > 80) {
    return '매우 좋아요'
  } else if (skinConditionScore > 65) {
    return '좋아요'
  } else if (skinConditionScore > 40) {
    return '보통이에요'
  } else if (skinConditionScore > 25) {
    return '나빠요'
  }
  else {
    return '매우 나빠요'
  }
}