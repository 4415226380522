import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IprevNextState } from '../../pages/Diagnosis_main/Diagnosis_main';
import styles from './Diagnosis_pre_result.module.css';


interface IpreResult {
  prevNextState: IprevNextState;
  setDiagnosisState: React.Dispatch<React.SetStateAction<string>>;
  elasticityScore: number
  breakoutScore: number
  sensitivityScore: number
  agingScore: number
  dehydrationScore: number
  odorScore: number
  darknessScore: number
  deadSkinScore: number
  recoveryScore: number
  itchyScore: number
  baseScore: number
}

const DiagnosisPreResult = (
  { prevNextState,
    setDiagnosisState,
    elasticityScore,
    breakoutScore,
    sensitivityScore,
    agingScore,
    dehydrationScore,
    odorScore,
    darknessScore,
    deadSkinScore,
    recoveryScore,
    itchyScore,
    baseScore,
  }: IpreResult
): React.ReactElement => {


  const navigate = useNavigate();

  const handleSubmitResults = () => {
    const skinScoreResults = {
      elasticityScore: parseFloat(elasticityScore.toFixed(1)),
      breakoutScore: parseFloat(breakoutScore.toFixed(1)),
      sensitivityScore: parseFloat(sensitivityScore.toFixed(1)),
      agingScore: parseFloat(agingScore.toFixed(1)),
      dehydrationScore: parseFloat(dehydrationScore.toFixed(1)),
      odorScore: parseFloat(odorScore.toFixed(1)),
      darknessScore: parseFloat(darknessScore.toFixed(1)),
      deadSkinScore: parseFloat(deadSkinScore.toFixed(1)),
      recoveryScore: parseFloat(recoveryScore.toFixed(1)),
      itchyScore: parseFloat(itchyScore.toFixed(1)),
      baseScore: parseFloat(baseScore.toFixed(1)),
    }
    localStorage.setItem('skinScoreResults', JSON.stringify(skinScoreResults));
    navigate('/diagnosis-end');
  }

  return (
    <div className={styles.section}>
      <div className={styles.titleCnt}>
        <h3 className={styles.mainTitle}>✔︎ 문진이 모두 끝났습니다.</h3>
        <h4 className={styles.subTitle}>피부 임상 데이터를 학습한 머신러닝 AI 알고리즘이 <br />사용자의 문진 항목을 분석하여,
      정밀한 피부진단 결과를 분석해드립니다.
      </h4>
      </div>
      <div className={styles.titleCnt}>
        <h3 className={styles.mainTitle}>✔︎ 분석 후에는 되돌아 갈 수 없습니다.</h3>
        <h4 className={styles.subTitle}> 분석 결과를 확인하신 후에는 진단 기록 탭에서 히스토리를 확인하실 수 있습니다.
      </h4>
      </div>

      <div className={styles.btnContainer}>
        <button className={styles.btn} onClick={() => setDiagnosisState(prevNextState.prev)}>문진수정</button>
        <button className={styles.btn} onClick={() => handleSubmitResults()}>AI분석</button>
      </div>
    </div>
  )
}

export default DiagnosisPreResult;