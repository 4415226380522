import React from "react";
import CartItem from '../../components/Cart_item/Cart_item';
import styles from "./Cart.module.css";

const Cart = () => {


  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <div className={styles.topSection}>
          <div className={`${styles.selectBtn}`}>
            <img src="/images/icons/check-white.png" alt='check_white_icon' />
          </div>
          <span>모두선택</span>
        </div>

        {/* 카트 아이템 섹션 */}
        <CartItem />
        <CartItem />
        <CartItem />
        {/* 하단 섹션 */}

        <div className={styles.priceBanner}>
          <div className={styles.priceBannerInner}>
            <h5>총 상품금액</h5>
            <h4>{numberWithCommas((12000) * (4))}원</h4>
          </div>
          <div className={styles.priceBannerInner}>
            <h5>배송비</h5>
            <h5>무료</h5>
          </div>
          <div className={styles.priceBannerInner}>
            <h3>총 결제금액</h3>
            <h3>{numberWithCommas((12000) * (4))}원</h3>
          </div>
        </div>

        {/* Putter 섹션 */}
        <div className={styles.putterSection}>
          <div className={styles.orderBtn}>결제하기</div>
        </div>
      </div>
    </section>
  );
};

export default Cart;

// product number counter
function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
