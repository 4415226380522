import React from 'react';
import OrderItem from '../../components/Order_item/Order_item';
import styles from './Order.module.css';

const Order = () => {

  return (
    <section className={styles.section}>
      <div className={styles.container}>

        {/* 카트 아이템 섹션 */}
        <OrderItem />
        <OrderItem />
      </div>
    </section>
  )
}

export default Order;

