import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Menu from '../Menu/Menu';
import styles from './Sub_navbar.module.css';

const SubNavbar = (): React.ReactElement => {

  const navigate = useNavigate()
  const [menuClicked, setMenuClicked] = useState(false)

  useEffect(() => {
    const body: any = document.querySelector("body");
    if (menuClicked) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "scroll";
    }
  }, [menuClicked])

  return (
    <div>
      <nav className={`${styles.navbar} ${menuClicked ? styles.menuClicked : ''}`}>
        <img
          onClick={() => navigate('/')}
          src='/logo.png'
          alt='actibiome logo'
          className={styles.logo} />
        <div className={styles.container}>
          <img alt='arrowleft' src='./images/icons/ArrowLeft.png' className={styles.arrow}
            onClick={() => navigate(-1)} />
          <img alt='list' src='./images/icons/List.png'
            className={styles.list}
            onClick={() => { setMenuClicked(true) }}
          />
        </div>
      </nav>
      {menuClicked &&
        <div className={styles.menu}>
          <div className={styles.emptyCnt}>
            <h2 className={styles.closeBtn}
              onClick={() => setMenuClicked(false)}>X</h2>
          </div>
          <Menu setMenuClicked={setMenuClicked} />
        </div>
      }
    </div>
  )
}

export default SubNavbar
