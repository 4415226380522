import React from 'react';
import { IskinConditionScore } from '../Diagnosis_result/Diagnosis_result';
import DiagnosisResultConditionScore from '../Diagnosis_result_conditionScore/Diagnosis_result_conditionScore';
import styles from './Diagnosis_result_condition.module.css';


interface IresultCondition {
  skinConditionScore: IskinConditionScore
}

export interface IskinConditionInfo {
  iconURl: string;
  conditionName: string;
}

const DiagnosisResultCondition = (
  { skinConditionScore }: IresultCondition
): React.ReactElement => {

  const skinConditionInfo = {
    elasticity: { iconURl: '/images/skinConditions/elasticity.png', conditionName: '탄력성' },
    breakout: { iconURl: '/images/skinConditions/breakout.png', conditionName: '트러블' },
    sensitivity: { iconURl: '/images/skinConditions/sensitivity.png', conditionName: '민감성' },
    aging: { iconURl: '/images/skinConditions/aging.png', conditionName: '노화' },
    dehydration: { iconURl: '/images/skinConditions/dehydration.png', conditionName: '수분부족' },
    odor: { iconURl: '/images/skinConditions/odor.png', conditionName: '체취' },
    darkness: { iconURl: '/images/skinConditions/darkness.png', conditionName: '색소침착' },
    deadSkin: { iconURl: '/images/skinConditions/deadSkin.png', conditionName: '각질' },
    recovery: { iconURl: '/images/skinConditions/recovery.png', conditionName: '피부재생력' },
    itchy: { iconURl: '/images/skinConditions/itchy.png', conditionName: '가려움' },
  }

  return (
    <div className={styles.section}>
      <h3 className={styles.category}>정밀피부 결과</h3>
      <h2 className={styles.title}>좀 더 자세히 알아볼게요.</h2>
      <DiagnosisResultConditionScore
        skinScore={skinConditionScore.agingScore}
        skinConditionInfo={skinConditionInfo.aging}
      />
      <DiagnosisResultConditionScore
        skinScore={skinConditionScore.breakoutScore}
        skinConditionInfo={skinConditionInfo.breakout}
      />
      <DiagnosisResultConditionScore
        skinScore={skinConditionScore.darknessScore}
        skinConditionInfo={skinConditionInfo.darkness}
      />
      <DiagnosisResultConditionScore
        skinScore={skinConditionScore.deadSkinScore}
        skinConditionInfo={skinConditionInfo.deadSkin}
      />
      <DiagnosisResultConditionScore
        skinScore={skinConditionScore.dehydrationScore}
        skinConditionInfo={skinConditionInfo.dehydration}
      />
      <DiagnosisResultConditionScore
        skinScore={skinConditionScore.elasticityScore}
        skinConditionInfo={skinConditionInfo.elasticity}
      />
      <DiagnosisResultConditionScore
        skinScore={skinConditionScore.itchyScore}
        skinConditionInfo={skinConditionInfo.itchy}
      />
      <DiagnosisResultConditionScore
        skinScore={skinConditionScore.odorScore}
        skinConditionInfo={skinConditionInfo.odor}
      />
      <DiagnosisResultConditionScore
        skinScore={skinConditionScore.recoveryScore}
        skinConditionInfo={skinConditionInfo.recovery}
      />
      <DiagnosisResultConditionScore
        skinScore={skinConditionScore.sensitivityScore}
        skinConditionInfo={skinConditionInfo.sensitivity}
      />
    </div>
  )
}

export default DiagnosisResultCondition;
