import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { parseJsonFromLocal } from '../Diagnosis_result/Diagnosis_result';
import styles from './Menu.module.css';

const Menu = ({ setMenuClicked }: { setMenuClicked: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const [name, setName] = useState('000')
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.getItem('userInfo') === null ? setName('000') : setName(parseJsonFromLocal('userInfo')['name'])
  }, [])


  const handleLogin = () => {
    localStorage.getItem("accessToken") ? navigate('/') : navigate('/login')
    setMenuClicked(false);
  }
  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userInfo');
    navigate('/')
  }

  return (
    <div className={styles.section}>
      <div className={styles.mainCnt}>
        <div>
          <div className={styles.mainText}>안녕하세요,</div>
          <div className={styles.mainText}>{name !== '000' ? `${name}님` : '환영합니다!'}</div>
          <div className={styles.btnContainer}>
            <div className={styles.btn}
              onClick={handleLogin}>{name !== '000' ? 'My 오더' : '로그인'}</div>
            {
              name !== '000' &&
              <div className={styles.btn}
                style={{ color: 'grey', backgroundColor: 'lightGrey' }}
                onClick={handleLogout}>
                로그아웃</div>
            }
          </div>
        </div>
      </div>
      <h4 className={styles.listMenu}>장바구니</h4>
      <h4 className={styles.listMenu}>나만의 화장품 제조</h4>
      <h4 className={styles.listMenu}>맞춤형 화장품 추천</h4>
      <h4 className={styles.listMenu}>내 화장품 적합도 분석</h4>
    </div>
  )
}

export default Menu;