import React, { useEffect } from 'react';
import HomeInfoBanner from '../../components/Home_infoBanner/HomeInfoBanner';
import HomeIngred from '../../components/Home_ingred/Home_ingred';
import HomePartner from '../../components/Home_partner/Home_partner';
import HomePutter from '../../components/Home_putter/Home_putter';
import { useNavigate } from 'react-router-dom';
import Service from '../../api/service';
import styles from './Home.module.css';
import HomeMain2 from '../../components/Home_main2/Home_main2';

export interface IhomeInfo {
  id: number,
  color: string,
  backgroundColor: string,
  url: string,
  subtitle: string,
  maintitle: string,
  title: string,
  desc: string[],
}

const Home = (): React.ReactElement => {

  //Scroll to Top 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])


  const navigate = useNavigate()
  const service = new Service(navigate);

  useEffect(() => {
    const href_URL = window.location.href;
    if (href_URL.includes('login/success?code=')) {

      const token = href_URL.split('=')[1]
      service.login(token);
    }
    // eslint-disable-next-line 
  }, [])

  const banner1Info: IhomeInfo = {
    id: 1,
    color: '#f6f6f6',
    backgroundColor: 'white',
    url: '/images/banner/banner1.png',
    subtitle: 'AI진단',
    maintitle: '50가지 문진으로 피부진단',
    title: '의사가 만든 문진으로 병원에 가지 않고 내 피부를 진단해보세요',
    desc: ['병원에 가서 피부과 의사가 물어보는 전문적인 질문을 문진으로 구현했습니다.',
      '대학병원 전문의와 박사 연구원들이 138개의 SCI의학 논문을 메타분석해서 만들었습니다.',
      '인공지능 머신러닝을 활용하여 피부진단 의료기기 수준의 측정 수준을 구현했습니다.']
  }

  const banner2Info: IhomeInfo = {
    id: 2,
    color: 'white',
    backgroundColor: '#f6f6f6',
    url: '/images/banner/banner2.png',
    subtitle: '맞춤형 화장품',
    maintitle: '3년간 100억원의 개발 비용',
    title: '12가지 피부 유익균을 기반으로 약 1억가지 맞춤형 솔루션을 개발했습니다.',
    desc: ['피부에 사는 유익한 미생물을 기반으로, 인체 친환경적인 성분만 사용했습니다.',
      '분당서울대병원, COSMAX 그리고 한국피부임상센터에서 3년동안 100억원을 들여 개발했습니다.',
      '정확한 피부진단 데이터를 기반으로 맞춤형 화장품을 제조해드립니다.']
  }

  return (
    <section className={styles.section}>
      <HomeMain2 />
      {/* <HomeMain /> */}
      <HomeInfoBanner bannerInfo={banner1Info} />
      <HomeInfoBanner bannerInfo={banner2Info} />
      <HomeIngred />
      <HomePartner />
      <HomePutter />
    </section>
  )
}

export default Home;

