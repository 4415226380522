import React from 'react';
import { ItypeInfo } from '../Diagnosis_result_type/Diagnosis_result_type';
import styles from './Diagnosis_result_typeScore.module.css';


interface ItypeScore {
  typeInfoLeft: ItypeInfo
  typeInfoRight: ItypeInfo
  leftTypeScore: number
  rightTypeScore: number
}


const DiagnosisResultTypeScore = (
  { typeInfoLeft, typeInfoRight, leftTypeScore, rightTypeScore }: ItypeScore
): React.ReactElement => {

  return (
    <div className={styles.section}>
      <div className={styles.typeScore}>
        <div className={styles.leftType}>
          <h3 className={styles.boldTitle}>{typeInfoLeft.typeName.charAt(0)}
            <span className={styles.Title}>{typeInfoLeft.typeName.substr(1)}</span>
          </h3>
          <h3 className={styles.korTitle}>{typeInfoLeft.typeNameKor}</h3>
        </div>
        <div className={styles.scoreBarCnt}>
          <p className={styles.percentLeft}>{Math.floor(leftTypeScore)}%</p>
          <p className={styles.percentRight}>{Math.floor(rightTypeScore)}%</p>
          <div className={styles.barCnt}>
            <div className={styles.bar}>
              <div className={styles.progressBarLeft}
                style={{ width: `${100 - leftTypeScore}%` }}></div>
            </div>
            <div className={styles.bar}>
              <div className={styles.progressBarRight}
                style={{ width: `${rightTypeScore}%` }}></div>
            </div>
          </div>
        </div>
        <div className={styles.rightType}>
          <h3 className={styles.boldTitle}>{typeInfoRight.typeName.charAt(0)}
            <span className={styles.Title}>{typeInfoRight.typeName.substr(1)}</span>
          </h3>
          <h3 className={styles.korTitle}>{typeInfoRight.typeNameKor}</h3>
        </div>
      </div>
    </div>
  )
}

export default DiagnosisResultTypeScore;