import React from 'react';
import styles from './Diagnosis_progressbar.module.css';


interface Iprogressbar {
  progressState: number
}

const DiagnosisProgressbar = (
  { progressState }: Iprogressbar
): React.ReactElement => {

  const progressPercent = Math.round(progressState);

  return (
    <div className={styles.container}>
      <h1 className={styles.mainTitle}>• AI 피부문진</h1>
      <div className={styles.barCnt}>
        <div className={styles.bar}>
          <div className={styles.progressBar}
            style={{ width: `${progressPercent}%` }}></div>
        </div>
        <p className={styles.percent}
          style={{ left: `${progressPercent}%`, transform: `translateX(-${progressPercent}%)` }}>
          {progressPercent}%</p>
      </div>
    </div>
  )
}

export default DiagnosisProgressbar;