import React, { useEffect } from 'react';
import PersonalProduct from '../../components/Personal_product/Personal_product';
import PersonalProductInfobBanner from '../../components/Personal_product_infoBanner/Personal_product_infobBanner';
import styles from './Personal_product_home.module.css';


const PersonalProductHome = (): React.ReactElement => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <section className={styles.section}>
      <PersonalProduct />
      <PersonalProductInfobBanner />
    </section>
  )
}

export default PersonalProductHome;