import React from 'react';
import styles from './Cart_item.module.css';

const CartItem = (): React.ReactElement => {



   return (
      <div className={styles.cartProduct}>
         <div className={styles.cartProductBanner}>
            <div className={`${styles.selectBtn}`}>
               <img src="/images/icons/check-white.png" alt='icon_check' />
            </div>
            <div className={styles.productImgCnt}>
               <img src="/images/sampleProduct/sample1.png" alt='sample_product' />
            </div>
            <div className={styles.cartInfo}>
               <h5 className={styles.cartInfoCategory}>액티바이옴 맞춤형 앰플/토너</h5>
            </div>
            <img src="/images/icons/close_small.png" alt='icon_close' className={styles.closeBtn} />
         </div>
         <div className={styles.optionCnt}>
            <p className={styles.option}>
               <span style={{ marginRight: '5px' }}>옵션1:</span>
               수분케어 샷 중농도
            </p>
            <p className={styles.option}>
               <span style={{ marginRight: '5px' }}>옵션1:</span>
               수분케어 샷 중농도
            </p>
         </div>
         <div className={styles.regulator}>
            <div className={styles.plusMinusIcon}>
               <span className={styles.btnRegulate} style={{ marginRight: ' 4px' }}>−</span>
               <span className={styles.quantity} style={{ marginRight: ' 12px' }}>1</span>
               <span className={styles.btnRegulate}>+</span>
            </div>
            <div>
               <span className={styles.productVol}>80ml</span>
               <span className={styles.productInfo}>{numberWithCommas(12000)}원 </span>
            </div>
         </div>
         <div className={styles.priceBanner}>
            <div className={styles.priceBannerInner}>
               <h5>상품금액</h5>
               <h4>{numberWithCommas((12000) * (4))}원</h4>
            </div>
            <div className={styles.priceBannerInner}>
               <h5>배송비</h5>
               <h5>무료</h5>
            </div>
         </div>
      </div>
   )
}

export default CartItem;


// product number counter
function numberWithCommas(x: number) {
   return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}