

export const generateDiagnosisResultAdvise = (state: number, type: string) => {
    if (state >= 80 && type === 'elasticity') {
        return [
            `피부 주름과 탄력이 매우 좋고 피부탄력지수가 상위 5%로 유지 단계입니다. 피부 주름, 탄력에 별다른 처방이 없어 현상태를 유지해야할 상황입니다. 
          평소 거울을 봤을 때 주름상태가 양호한편이고, 내 나이 또래 평균에 비해 나이가 많이 젊어 보이는 특성으로 보아 관리가 잘되고 있습니다.`,
            `피부탄력이 떨어지면 전체적인 얼굴 선이 흐트러지고 주름도 깊어집니다. 피부 탄력을 높이는 것은 곧 노화 진행 속도를 늦추는 것입니다. 철저한 관리로 피부 탄력을 강화할 수 있습니다.`
        ]
    }
    else if (state >= 55 && type === 'elasticity') {
        return [
            `피부 주름과 탄력이 양호하고 피부탄력지수가 상위 30%로 관심 단계입니다. 피부주름, 탄력에 관심을 가져야할 상황입니다.
          평소 거울을 봤을 때 주름상태가 잔주름이 있는 편이고, 내 나이 또래 평균에 비해 조금 젊어 보이는 특성으로 보아 관리를 시작하시길 권장 드립니다`,
            `피부탄력이 떨어지면 전체적인 얼굴 선이 흐트러지고 주름도 깊어집니다. 피부 탄력을 높이는 것은 곧 노화 진행 속도를 늦추는 것입니다. 철저한 관리로 피부 탄력을 강화할 수 있습니다.`
        ]
    }
    else if (state >= 35 && type === 'elasticity') {
        return [
            `피부 주름과 탄력이 보통이고 피부탄력지수가 상위 50%%로 주의 단계입니다. 피부 주름, 탄력에 관리를 시작해야할 상황입니다. 
          평소 거울을 봤을 때 주름상태가 양호한편이고, 내 나이 또래 평균에 비해 나이가 또래와 비슷해 보이는 특성으로 보아 관리가 필요한 것으로 판단 됩니다.`,
            `피부탄력이 떨어지면 전체적인 얼굴 선이 흐트러지고 주름도 깊어집니다. 피부 탄력을 높이는 것은 곧 노화 진행 속도를 늦추는 것입니다. 철저한 관리로 피부 탄력을 강화할 수 있습니다.`
        ]
    }
    else if (state < 35 && type === 'elasticity') {
        return [
            `피부 주름과 탄력이 심각하고 피부탄력지수가 상위 75%로 심각 단계입니다. 피부 주름, 탄력에 시급히 개선이 필요한 상황입니다. 
          평소 거울을 봤을 때 주름상태가 주름이 많은 편이고, 내 나이 또래 평균에 비해 나이가 늙어 보이는 특성으로 보아 관리가 시급합니다.`,
            `피부탄력이 떨어지면 전체적인 얼굴 선이 흐트러지고 주름도 깊어집니다. 피부 탄력을 높이는 것은 곧 노화 진행 속도를 늦추는 것입니다. 철저한 관리로 피부 탄력을 강화할 수 있습니다.`
        ]
    }
    else if (state >= 80 && type === 'breakout') {
        return [
            `피부 진정, 트러블상태가 매우좋고 피부진정지수가 상위5%로 유지 단계입니다. 피부 진정에 별다른 처방이 없어 현상태를 유지해야할 상황입니다.
          화장품 교체시나 평상시 트러블이 없고 외부 환경에 따라 피부 예민함이 없으며 화장품을 사용하면 얼굴에 가려움이나 따끔거림 증상이 안 나타나는 특성으로 보아 관리가 잘되고 있습니다.`,
            `진정 피부가 약하면 트러블이 자주발생하고 외부환경에 민감하여 신경 써서 관리 해야 합니다. 자극 받은 피부의 열감이나 통증을 가라 앉히고 진정시켜 트러블이나 붉게 보이는 피부를 진정시켜주세요.`
        ]
    }
    else if (state >= 55 && type === 'breakout') {
        return [
            `피부 진정, 트러블상태가 양호하고 피부진정지수가 상위30%로 관심 단계입니다. 피부진정에 관심을 가져야할 상황입니다.
          화장품 교체시나 평상시 트러블이 가끔 발생하고 외부 환경에 따라 피부 예민함이 없으며 화장품을 사용하면 얼굴에 가려움이나 따끔거림 증상이 안 나타나는 특성으로 보아 관리를 시작하시길 권장 드립니다.`,
            `진정 피부가 약하면 트러블이 자주발생하고 외부환경에 민감하여 신경 써서 관리 해야 합니다. 자극 받은 피부의 열감이나 통증을 가라 앉히고 진정시켜 트러블이나 붉게 보이는 피부를 진정시켜주세요.`
        ]
    }
    else if (state >= 35 && type === 'breakout') {
        return [
            `피부 진정, 트러블 상태가보통이고 피부진정지수가 상위50%로 경고 단계입니다. 피부진정에 관리를 시작해야할 상황입니다.
          화장품 교체시나 평상시 트러블이 자주 발생하고 외부 환경에 따라 피부 예민함이 자주 있고 화장품을 사용하면 얼굴에 가려움이나 따끔거림 증상이 자주 나타나는 특성으로 보아 관리가 필요한 것으로 판단 됩니다.`,
            `진정 피부가 약하면 트러블이 자주발생하고 외부환경에 민감하여 신경 써서 관리 해야 합니다. 자극 받은 피부의 열감이나 통증을 가라 앉히고 진정시켜 트러블이나 붉게 보이는 피부를 진정시켜주세요.`
        ]
    }
    else if (state < 35 && type === 'breakout') {
        return [
            `피부 진정, 트러블 상태가 심각하고 피부진정지수가 상위75%로 심각 단계입니다. 피부진정에 시급히 개선이 필요한 상황입니다. 
          화장품 교체시나 평상시 트러블이 항상 발생하고 외부 환경에 따라 피부 예민함이 항상 있고 화장품을 사용하면 얼굴에 가려움이나 따끔거림 증상이 항상 나타나는 특성으로 보아 관리가 시급합니다.`,
            `진정 피부가 약하면 트러블이 자주발생하고 외부환경에 민감하여 신경 써서 관리 해야 합니다. 자극 받은 피부의 열감이나 통증을 가라 앉히고 진정시켜 트러블이나 붉게 보이는 피부를 진정시켜주세요.`
        ]
    }
    else if (state >= 80 && type === 'sensitivity') {
        return [
            `피부 예민감과 피부장벽 상태가 매우좋고 피부장벽 지수가 상위5%로 유지 단계입니다. 피부장벽 강화에 별다른 처방이 없어 현상태를 유지해야할 상황입니다.
          평소 피부 예민함이 없고 피부 각질이 없는편 이라는 특성으로 보아 관리가 잘되고 있습니다.`,
            `장벽강화 피부는 가장 바깥쪽에 위치한 각질층으로 피부의 수분과 영양을 지켜주며 외부의 유해 물질로부터 피부를 보호해 주며,피부장벽이 붕괴될 경우 피부는 쉽게 건조해지고 각종 트러블에 노출됩니다.
          세안할 때에는 약산성으로 가볍게 씻고 유분이나 수분의 밸런스를 조절해 줘야합니다.`
        ]
    }
    else if (state >= 55 && type === 'sensitivity') {
        return [
            `피부 예민감과 피부장벽 상태가 양호하고 피부장벽지수가 상위30%로 관심 단계입니다. 피부장벽 강화에 관심을 가져야할 상황입니다.
          평소 피부 예민함이 약간 있고 피부 각질이 가끔 있는 편이라는 특성으로 보아 관리를 시작하시길 권장 드립니다.`,
            `장벽강화 피부는 가장 바깥쪽에 위치한 각질층으로 피부의 수분과 영양을 지켜주며 외부의 유해 물질로부터 피부를 보호해 주며,피부장벽이 붕괴될 경우 피부는 쉽게 건조해지고 각종 트러블에 노출됩니다.
          세안할 때에는 약산성으로 가볍게 씻고 유분이나 수분의 밸런스를 조절해 줘야합니다.`
        ]
    }
    else if (state >= 35 && type === 'sensitivity') {
        return [
            `피부 예민감과 피부장벽 상태가 보통이고 피부장벽지수가 상위50%로 경고 단계입니다. 피부장벽 강화에 관리를 시작해야할 상황입니다.
          평소 피부 예민함이 자주 있고 피부 각질이 종종 있는 편이라는 특성으로 보아 관리가 필요한 것으로 판단 됩니다.`,
            `장벽강화 피부는 가장 바깥쪽에 위치한 각질층으로 피부의 수분과 영양을 지켜주며 외부의 유해 물질로부터 피부를 보호해 주며,피부장벽이 붕괴될 경우 피부는 쉽게 건조해지고 각종 트러블에 노출됩니다.
          세안할 때에는 약산성으로 가볍게 씻고 유분이나 수분의 밸런스를 조절해 줘야합니다.`
        ]
    }
    else if (state < 35 && type === 'sensitivity') {
        return [
            `피부 예민감과 피부장벽 상태가 심각하고 피부장벽지수가 상위75%로 심각 단계입니다. 피부장벽 강화에 시급히 개선이 필요한 상황입니다. 
          평소 피부 예민함이 매우 심하고 피부 각질이 항상 있는 편이라는 특성으로 보아 관리가 시급합니다.`,
            `장벽강화 피부는 가장 바깥쪽에 위치한 각질층으로 피부의 수분과 영양을 지켜주며 외부의 유해 물질로부터 피부를 보호해 주며,피부장벽이 붕괴될 경우 피부는 쉽게 건조해지고 각종 트러블에 노출됩니다.
          세안할 때에는 약산성으로 가볍게 씻고 유분이나 수분의 밸런스를 조절해 줘야합니다.`
        ]
    }
    else if (state >= 80 && type === 'aging') {
        return [
            `일상생활 및 야외활동에서 자외선 차단 정도가 매우좋고 피부 광노화 지수가 상위5%로 유지 단계입니다. 피부 광노화에 별다른 처방이 없어 현상태를 유지해야할 상황입니다.
          햇볕을 받는 야외 활동이 없고 자외선차단 제품을 바르지 않으며 광노화 특성으로 보아 관리가 잘되고 있습니다.`,
            `광노화는 자외선을 자주, 오랜 기간 쐬어서 피부에 주름이 생기는 현상으로, 처음에는 피부가 거칠어지고 탄력이 떨어지며 건조해져 두꺼운 가죽과 같이 됩니다. 더불어 주근깨, 기미, 잡티와 같은 색소 침착이 일어나고 피부이 모세 혈관이 늘어나 피부가 붉어지기도 합니다.`
        ]
    }
    else if (state >= 55 && type === 'aging') {
        return [
            `일상생활 및 야외활동에서 자외선 차단 정도가 양호하고 피부 광노화 지수가 상위30%로 관심 단계입니다. 피부 광노화 에 관심을 가져야 할 상황 입니다.
          햇볕을 받는 야외 활동이 가끔 있고 자외선차단 제품을 여름철에만 바르며  광노화 특성으로 보아 관리를 시작하시길 권장 드립니다.`,
            `광노화는 자외선을 자주, 오랜 기간 쐬어서 피부에 주름이 생기는 현상으로, 처음에는 피부가 거칠어지고 탄력이 떨어지며 건조해져 두꺼운 가죽과 같이 됩니다. 더불어 주근깨, 기미, 잡티와 같은 색소 침착이 일어나고 피부이 모세 혈관이 늘어나 피부가 붉어지기도 합니다.`
        ]
    }
    else if (state >= 35 && type === 'aging') {
        return [
            `일상생활 및 야외활동에서 자외선 차단 정도가 보통이고 피부 광노화지수가 상위50%로 경고 단계입니다. 피부 광노화에 관리를 시작해야 할 상황입니다.
          햇볕을 받는 야외 활동이 종종 있고 자외선차단 제품을 외출할 때마다 바르며 광노화 특성으로 보아 관리가 필요한 것으로 판단 됩니다`,
            `광노화는 자외선을 자주, 오랜 기간 쐬어서 피부에 주름이 생기는 현상으로, 처음에는 피부가 거칠어지고 탄력이 떨어지며 건조해져 두꺼운 가죽과 같이 됩니다. 더불어 주근깨, 기미, 잡티와 같은 색소 침착이 일어나고 피부이 모세 혈관이 늘어나 피부가 붉어지기도 합니다.`
        ]
    }
    else if (state < 35 && type === 'aging') {
        return [
            `일상생활 및 야외활동에서 자외선 차단 정도가 심각하고 피부 광노화지수가 상위75%로 위험 단계입니다. 피부 광노화에 시급히 개선이 필요한 상황입니다.
          햇볕을 받는 야외 활동이 많고 자외선차단 제품을 수시로 덧바르며 광노화 특성으로 보아 관리가 시급합니다.`,
            `광노화는 자외선을 자주, 오랜 기간 쐬어서 피부에 주름이 생기는 현상으로, 처음에는 피부가 거칠어지고 탄력이 떨어지며 건조해져 두꺼운 가죽과 같이 됩니다. 더불어 주근깨, 기미, 잡티와 같은 색소 침착이 일어나고 피부이 모세 혈관이 늘어나 피부가 붉어지기도 합니다.`
        ]
    }
    else if (state >= 80 && type === 'dehydration') {
        return [
            `피부 유분감과 건조함이 매우좋고 피부 보습지수가 상위5%로 유지 단계입니다. 피부 보습에 별다른 처방이 없어 현상태를 유지해야할 상황입니다.
          건조한 환경에서 화장품을 상용하지 않으면, 피부가 아무 이상 없고 세안 후 속당김이 없습니다는 특성으로 보아 관리가 잘되고 있습니다.`,
            `보습은 수분을 유지해 주면서 피부 결을 부드럽게 해주는 걸로 특히 겨울이나 환절기때 금방 수분이 날아가서 관리가 필요합니다. 수분을 자주 공급해줘서 유지시켜 주기만 해도 노화에 따른 주름에도 좋고 매끄러운 피부 결을 유지할 수 있습니다.`
        ]
    }
    else if (state >= 55 && type === 'dehydration') {
        return [
            `피부 유분감과 건조함이 양호하고 피부 보습지수가 상위30%로 관심 단계입니다. 피부 보습에 관심을 가져야 할 상황 입니다.
          건조한 환경에서 화장품을 상용하지 않으면, 피부가 살짝 당기고세안 후 속당김이 가끔 있습니다는 특성으로 보아 관리를 시작하시길 권장 드립니다.`,
            `보습은 수분을 유지해 주면서 피부 결을 부드럽게 해주는 걸로 특히 겨울이나 환절기때 금방 수분이 날아가서 관리가 필요합니다. 수분을 자주 공급해줘서 유지시켜 주기만 해도 노화에 따른 주름에도 좋고 매끄러운 피부 결을 유지할 수 있습니다.`
        ]
    }
    else if (state >= 35 && type === 'dehydration') {
        return [
            `피부 유분감과 건조함이 보통이고 피부 보습지수가 상위50%로 경고 단계입니다. 피부 보습에 관리를 시작해야 할 상황입니다.
          건조한 환경에서 화장품을 상용하지 않으면, 피부가 당기는 느낌과 건조함이 오고 세안 후 속당김이 자주 있습니다는 특성으로 보아 관리가 필요한 것으로 판단 됩니다.`,
            `보습은 수분을 유지해 주면서 피부 결을 부드럽게 해주는 걸로 특히 겨울이나 환절기때 금방 수분이 날아가서 관리가 필요합니다. 수분을 자주 공급해줘서 유지시켜 주기만 해도 노화에 따른 주름에도 좋고 매끄러운 피부 결을 유지할 수 있습니다.`
        ]
    }
    else if (state < 35 && type === 'dehydration') {
        return [
            `피부 유분감과 건조함이 심각하고 피부 보습지수가 상위75%로 위험 단계입니다. 피부 보습에 시급히 개선이 필요한 상황입니다.
          건조한 환경에서 화장품을 상용하지 않으면, 피부가 매우 건조하고 갈라지는 느낌이 오고 세안 후 속당김이 항상 있습니다는 특성으로 보아 관리가 시급합니다.`,
            `보습은 수분을 유지해 주면서 피부 결을 부드럽게 해주는 걸로 특히 겨울이나 환절기때 금방 수분이 날아가서 관리가 필요합니다. 수분을 자주 공급해줘서 유지시켜 주기만 해도 노화에 따른 주름에도 좋고 매끄러운 피부 결을 유지할 수 있습니다.`
        ]
    }
    else if (state >= 80 && type === 'odor') {
        return [
            `피부 체취 상태가 매우좋고 피부 체취 지수가 상위5%로 유지 단계입니다. 피부 체취에 별다른 처방이 없어 현상태를 유지해야할 상황입니다.
          아침에 머리를 감으면, 오후에 두피에서 냄새가 나지 않으며 씻고난 후 본인의 피부에서 냄새가 안나고 평소 배변활동이 주 7회이상의 특성으로 보아 관리가 잘되고 있습니다.`,
            `피부 체취는 신진대사 기능이 떨어지고 노폐물의 배출속도나 피지 속의 여러 물질 등이 몸 밖으로 배출되는 속도가 저하되면서 몸 안에 겹겹이 쌓을 수 있습니다. 이때 쌓이는 물질들 가운데 노넨알데하이드가 퀴퀴한 냄새를 유발합니다. 이 물질이 모공에 쌓여서 부패하면 냄새를 일으킵니다.`
        ]
    }
    else if (state >= 55 && type === 'odor') {
        return [
            `피부 체취 상태가 양호하고 피부 체취 지수가 상위30%로 관심 단계입니다. 피부 체취에 관심을 가져야 할 상황 입니다.
          아침에 머리를 감으면, 오후에 두피에서 냄새가 가끔 나며 씻고난 후 본인의 피부에서 냄새가 가끔 나고 평소 배변활동이 주4회이상의 특성으로 보아 관리를 시작하시길 권장 드립니다.`,
            `피부 체취는 신진대사 기능이 떨어지고 노폐물의 배출속도나 피지 속의 여러 물질 등이 몸 밖으로 배출되는 속도가 저하되면서 몸 안에 겹겹이 쌓을 수 있습니다. 이때 쌓이는 물질들 가운데 노넨알데하이드가 퀴퀴한 냄새를 유발합니다. 이 물질이 모공에 쌓여서 부패하면 냄새를 일으킵니다.`
        ]
    }
    else if (state >= 35 && type === 'odor') {
        return [
            `피부 체취 상태가 보통이고 피부 체취 지수가 상위50%로 경고 단계입니다. 피부 체취에 관리를 시작해야 할 상황입니다.
          아침에 머리를 감으면, 오후에 두피에서 냄새가 자주 나며 씻고난 후 본인의 피부에서 냄새가 자주 나고 평소 배변활동이 주3회이상의 특성으로 보아 관리가 필요한 것으로 판단 됩니다.`,
            `피부 체취는 신진대사 기능이 떨어지고 노폐물의 배출속도나 피지 속의 여러 물질 등이 몸 밖으로 배출되는 속도가 저하되면서 몸 안에 겹겹이 쌓을 수 있습니다. 이때 쌓이는 물질들 가운데 노넨알데하이드가 퀴퀴한 냄새를 유발합니다. 이 물질이 모공에 쌓여서 부패하면 냄새를 일으킵니다.`
        ]
    }
    else if (state < 35 && type === 'odor') {
        return [
            `피부 체취 상태가 심각하고 피부 체취지수가 상위75%로 위험 단계입니다. 피부 체취에 시급히 개선이 필요한 상황입니다.
          아침에 머리를 감으면, 오후에 두피에서 냄새가 항상 나며 씻고난 후 본인의 피부에서 냄새가 항상 나고 평소 배변활동이 주2회이상의 특성으로 보아 관리가 시급합니다.`,
            `피부 체취는 신진대사 기능이 떨어지고 노폐물의 배출속도나 피지 속의 여러 물질 등이 몸 밖으로 배출되는 속도가 저하되면서 몸 안에 겹겹이 쌓을 수 있습니다. 이때 쌓이는 물질들 가운데 노넨알데하이드가 퀴퀴한 냄새를 유발합니다. 이 물질이 모공에 쌓여서 부패하면 냄새를 일으킵니다.`
        ]
    }
    else if (state >= 80 && type === 'darkness') {
        return [
            `피부 톤과 색소침착 상태가 매우좋고 피부 미백 지수가 상위5%로 유지 단계입니다. 피부 미백에 별다른 처방이 없어 현상태를 유지해야할 상황입니다.
          현재 피부 색깔이 하얀 편으로 얼굴에 기미, 주근깨 등 색소침착 상태가없고 평소 비타민C를 함유한 영양제나 음식을 꾸준히 섭취 특성으로 보아 관리가 잘되고 있습니다.`,
            `피부 미백은 기미나 주근깨, 잡티를 없애고 피부를 원래의 색으로 되돌리는 일입니다. 야외 활동 중 자외선으로 피부에 영향을 주고 주름 및 피부장벽에도 영향을 줍니다.`
        ]
    }
    else if (state >= 55 && type === 'darkness') {
        return [
            `피부 톤과 색소침착 상태가 양호하고 피부 미백 지수가 상위30%로 관심 단계입니다. 피부 미백에 관심 가져야 할 상황 입니다.
          현재 피부 색깔이 노란 편으로 얼굴에 기미, 주근깨 등 색소침착 상태가조금 있고 평소 비타민C를 함유한 영양제나 음식을 자주 섭취 특성으로 보아 관리를 시작하시길 권장 드립니다.`,
            `피부 미백은 기미나 주근깨, 잡티를 없애고 피부를 원래의 색으로 되돌리는 일입니다. 야외 활동 중 자외선으로 피부에 영향을 주고 주름 및 피부장벽에도 영향을 줍니다.`
        ]
    }
    else if (state >= 35 && type === 'darkness') {
        return [
            `피부 톤과 색소침착 상태가 보통이고 피부 미백 지수가 상위50%로 경고 단계입니다. 피부 미백에 관리 시작해야 할 상황입니다.
          현재 피부 색깔이 붉은 편으로 얼굴에 기미, 주근깨 등 색소침착 상태가군데군데 있고 평소 비타민C를 함유한 영양제나 음식을 가끔 섭취 특성으로 보아 관리가 필요한 것으로 판단 됩니다.`,
            `피부 미백은 기미나 주근깨, 잡티를 없애고 피부를 원래의 색으로 되돌리는 일입니다. 야외 활동 중 자외선으로 피부에 영향을 주고 주름 및 피부장벽에도 영향을 줍니다.`
        ]
    }
    else if (state < 35 && type === 'darkness') {
        return [
            `피부 톤과 색소침착 상태가 심각하고 피부 미백 지수가 상위75%로 위험 단계입니다. 피부 미백에 시급히 개선이 필요한 상황입니다.
          현재 피부 색깔이 어둡고 칙칙한 편으로 얼굴에 기미, 주근깨 등 색소침착 상태가얼굴 전체 있고 평소 비타민C를 함유한 영양제나 음식을 먹지 않는 특성으로 보아 관리가 시급합니다.`,
            `피부 미백은 기미나 주근깨, 잡티를 없애고 피부를 원래의 색으로 되돌리는 일입니다. 야외 활동 중 자외선으로 피부에 영향을 주고 주름 및 피부장벽에도 영향을 줍니다.`
        ]
    }

    else if (state >= 80 && type === 'deadSkin') {
        return [
            `피부 각질관리 상태가 매우좋고 피부 각질 지수가 상위5%로 유지 단계입니다. 피부 각질관리에 별다른 처방이 없어 현상태를 유지해야할 상황 입니다.
          평소 얼굴 각질 관리를 할 필요없고 계절에 따라 각질이 많이 안 생기는 특성으로 보아 관리가 잘되고 있습니다.`,
            `피부 각질은 피부의 바깥층에 위치에 있고 피부를 보호하는 역할도 하지만 죽은 각질층으로 인해 각종피부 트러블의 원인이 되기도 합니다.`
        ]
    }
    else if (state >= 55 && type === 'deadSkin') {
        return [
            `피부 각질관리 상태가 양호하고 피부 각질 지수가 상위30%로 관심 단계입니다. 피부 각질관리에 관심을 가져야 할 상황  입니다.
          평소 얼굴 각질 관리를 주1회 이상 계절에 따라 각질이 많이 가끔 생기는 특성으로 보아 관리를 시작하시길 권장 드립니다.`,
            `피부 각질은 피부의 바깥층에 위치에 있고 피부를 보호하는 역할도 하지만 죽은 각질층으로 인해 각종피부 트러블의 원인이 되기도 합니다.`
        ]
    }
    else if (state >= 35 && type === 'deadSkin') {
        return [
            `피부 각질관리 상태가 보통이고 피부 각질 지수가 상위50%로 경고 단계입니다. 피부 각질관리에 관리를 시작해야 할 상황 입니다.
          평소 얼굴 각질 관리를 한달1~2회 계절에 따라 각질이 많이 봄,가을 환절기에 많이 생기는 특성으로 보아 관리가 필요한 것으로 판단 됩니다.`,
            `피부 각질은 피부의 바깥층에 위치에 있고 피부를 보호하는 역할도 하지만 죽은 각질층으로 인해 각종피부 트러블의 원인이 되기도 합니다.`
        ]
    }
    else if (state < 35 && type === 'deadSkin') {
        return [
            `피부 각질관리 상태가 심각하고 피부 각질 지수가 상위75%로 위험 단계입니다. 피부 각질관리에 시급히 개선이 필요한 상황 입니다.
          평소 얼굴 각질 관리를 하지 않고 계절에 따라 각질이 많이 생기는 특성으로 보아 관리가 시급합니다.`,
            `피부 각질은 피부의 바깥층에 위치에 있고 피부를 보호하는 역할도 하지만 죽은 각질층으로 인해 각종피부 트러블의 원인이 되기도 합니다.`
        ]
    }
    else if (state >= 80 && type === 'recovery') {
        return [
            `피부 트러블 및 수면 상태가 매우좋고 피부 재생 지수가 상위5%로 유지 단계입니다. 피부 재생을 위해 별다른 처방이 없어 현상태를 유지해야 할 상황입니다.
          일정한 시간에 취침을 하고 얼굴에 여드름이나 피부 트러블이없는 특성으로 보아 관리가 잘되고 있습니다.`,
            `피부 재생은 진피층에서 만들어진 각질형성세포가 분열 증식을 통해 각질층까지 올라와 죽은 세포가 되어 떨어져 나가는 것으로 충분한 수면 과 관리가 중요합니다.`
        ]
    }
    else if (state >= 55 && type === 'recovery') {
        return [
            `피부 트러블 및 수면 상태가 양호하고 피부 재생 지수가 상위30%로 관심 단계입니다. 피부 재생을 위해 관심을 가져야할 상황 입니다.
          일정한 시간에 취침을 가끔 하고 얼굴에 여드름이나 피부 트러블이과거에는 있었지만 현재는 없는 특성으로 보아 관리를 시작하시길 권장 드립니다.`,
            `피부 재생은 진피층에서 만들어진 각질형성세포가 분열 증식을 통해 각질층까지 올라와 죽은 세포가 되어 떨어져 나가는 것으로 충분한 수면 과 관리가 중요합니다.`
        ]
    }
    else if (state >= 35 && type === 'recovery') {
        return [
            `피부 트러블 및 수면 상태가 보통이고 피부 재생 지수가 상위50%로 경고 단계입니다. 피부 재생을 위해 관리를 시작해야 할 상황입니다.
          일정한 시간에 취침을 어쩌다 하고 얼굴에 여드름이나 피부 트러블이가끔 생기는 특성으로 보아 관리가 필요한 것으로 판단됩니다.`,
            `피부 재생은 진피층에서 만들어진 각질형성세포가 분열 증식을 통해 각질층까지 올라와 죽은 세포가 되어 떨어져 나가는 것으로 충분한 수면 과 관리가 중요합니다.`
        ]
    }
    else if (state < 35 && type === 'recovery') {
        return [
            `피부 트러블 및 수면 상태가 심각하고 피부 재생 지수가 상위75%로 위험 단계입니다. 피부 재생을 위해 시급히 개선이 필요한 상황입니다.
          일정한 시간에 취침을 아예 못하고 얼굴에 여드름이나 피부 트러블이많은 특성으로 보아 관리가 시급합니다.`,
            `피부 재생은 진피층에서 만들어진 각질형성세포가 분열 증식을 통해 각질층까지 올라와 죽은 세포가 되어 떨어져 나가는 것으로 충분한 수면 과 관리가 중요합니다.`
        ]
    }
    else if (state >= 80 && type === 'itchy') {
        return [
            `피부 건조함과 외부요인으로 인한 피부 가려움 상태가 매우좋고 피부 가려움 지수가 상위5%로 유지 단계입니다. 피부 가려움에 별다른 처방이 없어 현상태를 유지해야할 상황입니다.
          피부 아토피가 없고 건조함으로 인해 피부 가려움증을 느낀 적이 없는 특성으로 보아 관리가 잘되고 있습니다.`,
            `가려움은 피부염이나 피부 건조로 인해 생기며 피부가 건조한 사람들은 가을, 겨울철 피부 건조로 인해 가려움증이 심화될 수있습니다. 가려움증으로 동반하는 질환으로 대표적인 것은 피부염, 습진, 아토피성 피부염이 있습니다.`
        ]
    }
    else if (state >= 55 && type === 'itchy') {
        return [
            `피부 건조함과 외부요인으로 인한 피부 가려움 상태가 양호하고 피부 가려움 지수가 상위30%로 관심 단계입니다. 피부 가려움에 관심을 가져야 할 상황입니다.
          피부 아토피가 과거에 있었지만 현재는 없고 건조함으로 인해 피부 가려움증을 느낀 적이 가끔 있는 특성으로 보아 관리를 시작하시길 권장 드립니다.`,
            `가려움은 피부염이나 피부 건조로 인해 생기며 피부가 건조한 사람들은 가을, 겨울철 피부 건조로 인해 가려움증이 심화될 수있습니다. 가려움증으로 동반하는 질환으로 대표적인 것은 피부염, 습진, 아토피성 피부염이 있습니다.`
        ]
    }
    else if (state >= 35 && type === 'itchy') {
        return [
            `피부 건조함과 외부요인으로 인한 피부 가려움 상태가 보통이고 피부 가려움 지수가 상위50%로 경고 단계입니다. 피부 가려움에 관리를 시작해야 할 상황입니다.
          피부 아토피가 약은 사용하지 않지만 경미한 증상이 있고 건조함으로 인해 피부 가려움증을 느낀 적이 자주 있는 특성으로 보아 관리가 필요한 것으로 판단 됩니다.`,
            `가려움은 피부염이나 피부 건조로 인해 생기며 피부가 건조한 사람들은 가을, 겨울철 피부 건조로 인해 가려움증이 심화될 수있습니다. 가려움증으로 동반하는 질환으로 대표적인 것은 피부염, 습진, 아토피성 피부염이 있습니다.`
        ]
    }
    else if (state < 35 && type === 'itchy') {
        return [
            `피부 건조함과 외부요인으로 인한 피부 가려움 상태가 심각하고 피부 가려움 지수가 상위75%로 위험 단계입니다. 피부 가려움에 시급히 개선이 필요한 상황입니다.
          피부 아토피가 약은 사용하고 있는 심각한 증상이 있고 건조함으로 인해 피부 가려움증을 느낀 적이 항상 있는 특성으로 보아 관리가 시급합니다.`,
            `가려움은 피부염이나 피부 건조로 인해 생기며 피부가 건조한 사람들은 가을, 겨울철 피부 건조로 인해 가려움증이 심화될 수있습니다. 가려움증으로 동반하는 질환으로 대표적인 것은 피부염, 습진, 아토피성 피부염이 있습니다.`
        ]
    }
}
