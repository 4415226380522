import {  NavigateFunction } from 'react-router-dom';
import ServiceClient from './serviceClient';

const client = new ServiceClient();

export default class Service {
  constructor(
    private navigate? : NavigateFunction
  ) {
  }

  async login(token :string){
    client
    .login({headers : {'Authorization' : `Bearer ${token}`}})
    .then((response) => {
      localStorage.setItem('accessToken', token);
      localStorage.setItem('userInfo', JSON.stringify(response.data.data));
    })
    .then(()=> {
      typeof(this.navigate) !== 'undefined' && this.navigate('/');
    })
    .catch((error) => console.log(error))
  }
}