import React from 'react';
import styles from './Personal_product_bundleItem.module.css';


export interface IprescribedBundleItem {
  score: number
  iconURL: string
  engName: string
  name: string
  desc: string
}


interface IbundleItem {
  category: string
  prescribedBundleItemFirst: IprescribedBundleItem
  prescribedBundleItemSecond?: IprescribedBundleItem
}

const PersonalProductBundleItem = (
  { prescribedBundleItemFirst, prescribedBundleItemSecond, category }: IbundleItem
): React.ReactElement => {

  const bundleInfo = [
    {
      name: '노화케어',
    },
    {
      name: '트러블케어',
    },
    {
      name: '색소케어',
    },
    {
      name: '각질케어',
    },
    {
      name: '수분케어',
    },
    {
      name: '탄력케어',
    },
    {
      name: '가려움케어',
    },
    {
      name: '체취케어',
    },
    {
      name: '피부재생',
    },
    {
      name: '피부진정',
    },
  ]

  return (
    <div className={styles.section}>
      <h3 className={styles.mainTitle}>{category}</h3>

      <div className={styles.btnContainer}>
        {bundleInfo.map((bundle, index) => <div
          key={index}
          className={`${styles.btn} 
          ${prescribedBundleItemFirst.name === bundle.name ? styles.selected : ''}
          ${prescribedBundleItemSecond?.name === bundle.name ? styles.selected : ''}
          `}>
          {bundle.name}</div>)}
      </div>

      <div className={styles.infoBox}>
        {bundleInfo.map((bundle, index) => {
          if (prescribedBundleItemFirst.name === bundle.name) {
            return (
              <div className={styles.infoBoxCnt} key={prescribedBundleItemFirst.engName}>
                <div className={styles.bundleIconCnt}>
                  <img className={styles.bundleIcon} src={prescribedBundleItemFirst.iconURL} alt='base' />
                  <h5 className={styles.iconTitle}>{prescribedBundleItemFirst.name}</h5>
                </div>
                <p key={index} className={styles.desc}>{prescribedBundleItemFirst.desc}<p className={styles.goToDetail}>자세히보기</p></p>
              </div>
            )
          }
          return null
        })}
        {bundleInfo.map((bundle, index) => {
          if (prescribedBundleItemSecond?.name === bundle.name) {
            return <div className={styles.infoBoxCnt} key={prescribedBundleItemSecond.engName}>
              <div className={styles.bundleIconCnt}>
                <img className={styles.bundleIcon} src={prescribedBundleItemSecond?.iconURL} alt='base' />
                <h5 className={styles.iconTitle}>{prescribedBundleItemSecond?.name}</h5>
              </div>
              <p key={index} className={styles.desc}>{prescribedBundleItemSecond?.desc}<p className={styles.goToDetail}>자세히보기</p></p>
            </div>
          }
          return null
        })}
      </div>
    </div>
  )
}

export default PersonalProductBundleItem;