import React from 'react';
import styles from './Login.module.css';

const Login = (): React.ReactElement => {

    // const baseURL = process.env.REACT_APP_BASE_URL;
    const baseURL = process.env.REACT_APP_BASE_URL;
    const onLogin = (provider: string) => {
        window.open(`${baseURL}/auth/${provider}`, "_self");
    }

    return (
        <div className={styles.section}>
            <h2 className={styles.mainText}>
                ✔︎ No.1 맞춤형 코스메틱 브랜드
                    <br /> <span style={{ fontWeight: '800' }}>액티바이옴</span>에 오신 것을 <br />
                    환영합니다!
                </h2>
            <div className={styles.box} style={{ backgroundColor: "#FFE539", color: "black" }}
                onClick={() => onLogin('kakao')}>
                <img src='/images/icons/Kakao.png' alt='login' className={styles.icon} />
                    카카오 계정으로 로그인
                </div>
            <div className={styles.box} style={{ backgroundColor: "#fff", color: "black" }}
                onClick={() => onLogin('google')}>
                <img src='/images/icons/Google.png' alt='login' className={styles.icon} />
                    Google로 로그인
                </div>
            <div className={styles.box} style={{ backgroundColor: "#03CF5D" }}
                onClick={() => onLogin('naver')}>
                <img src='/images/icons/Naver.png' alt='login' className={styles.icon} />
                    네이버로 아이디로 로그인
                </div>
            <div className={styles.box} style={{ backgroundColor: "black" }}
                onClick={() => alert('모바일 앱에서만 가능합니다.')}>
                <img src='/images/icons/Apple.png' alt='login' className={styles.icon} />
                        애플 아이디로 로그인
                </div>
        </div>
    )
}

export default Login;