import React, { useEffect, useState } from 'react';
import DiagnosisPreResult from '../../components/Diagnosis_pre_result/Diagnosis_pre_result';
import DiagnosisProgressbar from '../../components/Diagnosis_progressbar/Diagnosis_progressbar';
import DiagnosisQuestion from '../../components/Diagnosis_question/Diagnosis_question';
import DiagnosisReady from '../../components/Diagnosis_ready/Diagnosis_ready';
import { Questions } from '../../data/Questions';
import styles from './Diagnosis_main.module.css';



export interface IprevNextState {
  prev: string
  next: string
}

export interface IquestionDataItem {
  id: number;
  type: number;
  point: number;
  prompt: string;
  optionA: string;
  optionB: string;
  optionC: string;
  optionD: string;
}

const DiagnosisMain = (): React.ReactElement => {

  const questions = Questions;
  const [diagnosisState, setDiagnosisState] = useState('ready')
  // eslint-disable-next-line
  const [prevNextState, setPrevNextState] = useState({
    'elasticity': {
      'prev': 'ready',
      'next': 'breakout',
    },
    'breakout': {
      'prev': 'elasticity',
      'next': 'sensitivity',
    },
    'sensitivity': {
      'prev': 'breakout',
      'next': 'aging',
    },
    'aging': {
      'prev': 'sensitivity',
      'next': 'dehydration',
    },
    'dehydration': {
      'prev': 'aging',
      'next': 'odor',
    },
    'odor': {
      'prev': 'dehydration',
      'next': 'darkness',
    },
    'darkness': {
      'prev': 'odor',
      'next': 'deadSkin',
    },
    'deadSkin': {
      'prev': 'darkness',
      'next': 'recovery',
    },
    'recovery': {
      'prev': 'deadSkin',
      'next': 'itchy',
    },
    'itchy': {
      'prev': 'deadSkin',
      'next': 'base',
    },
    'base': {
      'prev': 'itchy',
      'next': 'preResult',
    },
    'preResult': {
      'prev': 'ready',
      'next': '',
    }
  })
  // progress state
  const [progressState, setProgressState] = useState(0)

  // skin score state
  const [elasticityScore, setElasticityScore] = useState(0)
  const [breakoutScore, setBreakoutScore] = useState(0)
  const [sensitivityScore, setSensitivityScore] = useState(0)
  const [agingScore, setAgingScore] = useState(0)
  const [dehydrationScore, setDehydrationScore] = useState(0)
  const [odorScore, setOdorScore] = useState(0)
  const [darknessScore, setDarknessScore] = useState(0)
  const [deadSkinScore, setDeadSkinScore] = useState(0)
  const [recoveryScore, setRecoveryScore] = useState(0)
  const [itchyScore, setItchyScore] = useState(0)
  const [baseScore, setBaseScore] = useState(0)

  // scroll to top every diagnosis state
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [diagnosisState])

  return (
    <section className={styles.section}>
      {<div className={`${diagnosisState !== 'ready' ? styles.visible : styles.invisible} ${styles.progressBar}`}>
        <DiagnosisProgressbar progressState={progressState} />
      </div>}
      {
        <div className={`${diagnosisState === 'ready' ? styles.visible : styles.invisible}`}>
          <DiagnosisReady setDiagnosisState={setDiagnosisState} />
        </div>
      }
      {
        <div className={`${diagnosisState === 'elasticity' ? styles.visible : styles.invisible}`}>
          <DiagnosisQuestion
            type='탄력도'
            prevNextState={prevNextState.elasticity}
            questionData={questions.elasticity}
            setDiagnosisState={setDiagnosisState}
            setProgressState={setProgressState}
            setSkinScore={setElasticityScore}
          />
        </div>
      }
      {
        <div className={`${diagnosisState === 'breakout' ? styles.visible : styles.invisible}`}>
          <DiagnosisQuestion
            type='피부트러블'
            prevNextState={prevNextState.breakout}
            questionData={questions.breakout}
            setDiagnosisState={setDiagnosisState}
            setProgressState={setProgressState}
            setSkinScore={setBreakoutScore}
          />
        </div>
      }
      {
        <div className={`${diagnosisState === 'sensitivity' ? styles.visible : styles.invisible}`}>
          <DiagnosisQuestion
            type='민감도'
            prevNextState={prevNextState.sensitivity}
            questionData={questions.sensitivity}
            setDiagnosisState={setDiagnosisState}
            setProgressState={setProgressState}
            setSkinScore={setSensitivityScore}
          />
        </div>
      }
      {
        <div className={`${diagnosisState === 'aging' ? styles.visible : styles.invisible}`}>
          <DiagnosisQuestion
            type='노화도'
            prevNextState={prevNextState.aging}
            questionData={questions.aging}
            setDiagnosisState={setDiagnosisState}
            setProgressState={setProgressState}
            setSkinScore={setAgingScore}
          />
        </div>
      }
      {
        <div className={`${diagnosisState === 'dehydration' ? styles.visible : styles.invisible}`}>
          <DiagnosisQuestion
            type='수분부족도'
            prevNextState={prevNextState.dehydration}
            questionData={questions.dehydration}
            setDiagnosisState={setDiagnosisState}
            setProgressState={setProgressState}
            setSkinScore={setDehydrationScore}
          />
        </div>
      }
      {
        <div className={`${diagnosisState === 'odor' ? styles.visible : styles.invisible}`}>
          <DiagnosisQuestion
            type='체취'
            prevNextState={prevNextState.odor}
            questionData={questions.odor}
            setDiagnosisState={setDiagnosisState}
            setProgressState={setProgressState}
            setSkinScore={setOdorScore}
          />
        </div>
      }
      {
        <div className={`${diagnosisState === 'darkness' ? styles.visible : styles.invisible}`}>
          <DiagnosisQuestion
            type='색소침착'
            prevNextState={prevNextState.darkness}
            questionData={questions.darkness}
            setDiagnosisState={setDiagnosisState}
            setProgressState={setProgressState}
            setSkinScore={setDarknessScore}
          />
        </div>
      }
      {
        <div className={`${diagnosisState === 'deadSkin' ? styles.visible : styles.invisible}`}>
          <DiagnosisQuestion
            type='각질'
            prevNextState={prevNextState.deadSkin}
            questionData={questions.deadSkin}
            setDiagnosisState={setDiagnosisState}
            setProgressState={setProgressState}
            setSkinScore={setDeadSkinScore}
          />
        </div>
      }
      {
        <div className={`${diagnosisState === 'recovery' ? styles.visible : styles.invisible}`}>
          <DiagnosisQuestion
            type='피부재생력'
            prevNextState={prevNextState.recovery}
            questionData={questions.recovery}
            setDiagnosisState={setDiagnosisState}
            setProgressState={setProgressState}
            setSkinScore={setRecoveryScore}
          />
        </div>
      }
      {
        <div className={`${diagnosisState === 'itchy' ? styles.visible : styles.invisible}`}>
          <DiagnosisQuestion
            type='가려움'
            prevNextState={prevNextState.itchy}
            questionData={questions.itchy}
            setDiagnosisState={setDiagnosisState}
            setProgressState={setProgressState}
            setSkinScore={setItchyScore}
          />
        </div>
      }
      {
        <div className={`${diagnosisState === 'base' ? styles.visible : styles.invisible}`}>
          <DiagnosisQuestion
            type='생활습관'
            prevNextState={prevNextState.base}
            questionData={questions.base}
            setDiagnosisState={setDiagnosisState}
            setProgressState={setProgressState}
            setSkinScore={setBaseScore}
          />
        </div>
      }
      {
        <div className={`${diagnosisState === 'preResult' ? styles.visible : styles.invisible}`}>
          <DiagnosisPreResult
            prevNextState={prevNextState.preResult}
            setDiagnosisState={setDiagnosisState}
            elasticityScore={elasticityScore}
            breakoutScore={breakoutScore}
            sensitivityScore={sensitivityScore}
            agingScore={agingScore}
            dehydrationScore={dehydrationScore}
            odorScore={odorScore}
            darknessScore={darknessScore}
            deadSkinScore={deadSkinScore}
            recoveryScore={recoveryScore}
            itchyScore={itchyScore}
            baseScore={baseScore}
          />
        </div>
      }
    </section>
  )
}

export default DiagnosisMain;