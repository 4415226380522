import React from 'react';
import { IomrTypeInfo } from '../Diagnosis_result/Diagnosis_result';
import DiagnosisResultTypeIcon from '../Diagnosis_result_typeIcon/Diagnosis_result_typeIcon';
import DiagnosisResultTypeScore from '../Diagnosis_result_typeScore/Diagnosis_result_typeScore';
import styles from './Diagnosis_result_type.module.css';


export interface ItypeInfo {
  iconURL: string
  typeName: string
  typeNameKor: string
  desc?: string
}

interface IresultType {
  omrTypeInfo: IomrTypeInfo
}

const DiagnosisResultType = (
  { omrTypeInfo }: IresultType
): React.ReactElement => {

  const typeInfo =
  {
    dry: { iconURL: './images/skinTypes/Dry.png', typeName: 'Dry', typeNameKor: '수분부족' },
    moistured: { iconURL: './images/skinTypes/Moistured.png', typeName: 'Moistured', typeNameKor: '수분정상' },
    notOily: { iconURL: './images/skinTypes/NotOily.png', typeName: 'Not-oily', typeNameKor: '건성' },
    oily: { iconURL: './images/skinTypes/Oily.png', typeName: 'Oily', typeNameKor: '지성' },
    resistant: { iconURL: './images/skinTypes/Resistant.png', typeName: 'Resistant', typeNameKor: '저항성' },
    sensitive: { iconURL: './images/skinTypes/Sensitive.png', typeName: 'Sensitive', typeNameKor: '민감성' },
  }

  return (
    <div className={styles.section}>
      <h3 className={styles.category}>피부타입 결과</h3>
      <h1 className={styles.mainTitle}>홍길동님의 <br /><span style={{ fontWeight: '800' }}>피부 타입은?</span></h1>

      <div className={styles.typeIconCnt}>
        {omrTypeInfo.skinTypeSymbol.includes('O') && <DiagnosisResultTypeIcon typeInfo={typeInfo.oily} />}
        {omrTypeInfo.skinTypeSymbol.includes('N') && <DiagnosisResultTypeIcon typeInfo={typeInfo.notOily} />}
        {omrTypeInfo.skinTypeSymbol.includes('M') && <DiagnosisResultTypeIcon typeInfo={typeInfo.moistured} />}
        {omrTypeInfo.skinTypeSymbol.includes('D') && <DiagnosisResultTypeIcon typeInfo={typeInfo.dry} />}
        {omrTypeInfo.skinTypeSymbol.includes('R') && <DiagnosisResultTypeIcon typeInfo={typeInfo.resistant} />}
        {omrTypeInfo.skinTypeSymbol.includes('S') && <DiagnosisResultTypeIcon typeInfo={typeInfo.sensitive} />}
      </div>

      <div className={styles.infoBox}>
        <h3 className={styles.infoBoxTitle}>{omrTypeInfo.skinType}</h3>
        <p className={styles.infoBoxDesc}>{omrTypeInfo.desc}</p>
      </div>

      {/* Oily vs non-oily */}
      <DiagnosisResultTypeScore
        typeInfoLeft={typeInfo.oily} typeInfoRight={typeInfo.notOily}
        leftTypeScore={omrTypeInfo.oilScore} rightTypeScore={omrTypeInfo.notOliScore} />
      {/* Moistured vs Dry */}
      <DiagnosisResultTypeScore
        typeInfoLeft={typeInfo.moistured} typeInfoRight={typeInfo.dry}
        leftTypeScore={omrTypeInfo.moistScore} rightTypeScore={omrTypeInfo.dryScore} />
      {/* Resistant vs Sensitive */}
      <DiagnosisResultTypeScore
        typeInfoLeft={typeInfo.resistant} typeInfoRight={typeInfo.sensitive}
        leftTypeScore={omrTypeInfo.resisScore} rightTypeScore={omrTypeInfo.sensiScore} />

    </div>
  )
}

export default DiagnosisResultType;