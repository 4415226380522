import React from 'react';
import { IfigureBoxInfoTheme } from '../Personal_product/Personal_product';
import styles from './Personal_product_figureBoxInfo.module.css';


interface IfigureBoxInfo {
  FigureBoxInfoTheme: IfigureBoxInfoTheme
  nameFirst?: string
  nameSecond?: string
}


const PersonalProductFigureBoxInfo = (
  { FigureBoxInfoTheme, nameFirst, nameSecond }: IfigureBoxInfo
): React.ReactElement => {

  return (
    <div
      className={styles.section}
      style={{
        backgroundColor: `${FigureBoxInfoTheme.bgColor}`,
      }}>
      <div className={styles.figureBoxTitle} style={{ color: `${FigureBoxInfoTheme.fontM}` }}>{FigureBoxInfoTheme.name}
        <span
          className={styles.percent}
          style={{
            color: `${FigureBoxInfoTheme.fontM}`,
            border: `2px solid ${FigureBoxInfoTheme.fontM}`
          }}>{FigureBoxInfoTheme.percent}</span>
        <span className={styles.desc}
          style={{ color: `${FigureBoxInfoTheme.fontS}` }}>
          {nameFirst} {nameSecond}</span>
      </div>
    </div>
  )
}
export default PersonalProductFigureBoxInfo;