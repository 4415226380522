import React from 'react';
import styles from './Personal_product_infoBanner.module.css';

const PersonalProductInfobBanner = (): React.ReactElement => {


  return (
    <div className={styles.section}>
      <h3 className={styles.subTitle}>맞춤형 화장품은</h3>
      <h1 className={styles.mainTitle}>어떻게 만들어지나요?</h1>
      <p className={styles.desc}>피부에 사는 유익균에서 추출한 <br /> 10가지 스킨 마이크로바이옴!</p>
      <div className={styles.goToDetail}>자세히보기</div>
    </div>
  )
}

export default PersonalProductInfobBanner;