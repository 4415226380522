import React from 'react';
import { IprevNextState, IquestionDataItem } from '../../pages/Diagnosis_main/Diagnosis_main';
import DiagnosisPreNextBtn from '../Diagnosis_pre_next_btn/Diagnosis_pre_next_btn';
import DiagnosisQuestionItem from '../Diagnosis_questionItem/Diagnosis_questionItem';
import styles from './Diagnosis_question.module.css';



interface IDiagnosisQuestion {
  type: string
  prevNextState: IprevNextState
  questionData: IquestionDataItem[]
  setDiagnosisState: React.Dispatch<React.SetStateAction<string>>
  setProgressState: React.Dispatch<React.SetStateAction<number>>
  setSkinScore: React.Dispatch<React.SetStateAction<number>>
}

const DiagnosisQuestion = (
  { type,
    prevNextState,
    questionData,
    setDiagnosisState,
    setProgressState,
    setSkinScore }: IDiagnosisQuestion

): React.ReactElement => {

  return (
    <div className={styles.section}>
      <h1 className={styles.mainTitle}>
        <span className={styles.icon}> ☑︎ </span>
        {type}에 대해 측정하는 질문입니다.</h1>
      <span className={styles.pass}
        onClick={() => setDiagnosisState(prevNextState.prev)}> 이전</span>
      <span className={styles.pass}
        onClick={() => setDiagnosisState(prevNextState.next)}> 다음 </span>
      {questionData.map((item) =>
        <div key={item.id}>
          <DiagnosisQuestionItem
            questionItmeData={item}
            setProgressState={setProgressState}
            setSkinScore={setSkinScore}
          />
        </div>
      )}
      <DiagnosisPreNextBtn
        preNextState={prevNextState}
        setDiagnosisState={setDiagnosisState}
      />
    </div>
  )
}

export default DiagnosisQuestion;