import axios from 'axios';

export default class ServiceClient {
  constructor(
    public httpClient? : any
  ) {
    this.httpClient = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      headers : {
        'accept': "*/*",
      },
    });
  }

  async login(params : any) {
    return this.httpClient.get('/auth', params)
  }
}
