import React from 'react';
import { useNavigate } from 'react-router-dom';
import { generateOMRType } from '../../api/diagnosis_omrType';
import DiagnosisResultAdvise from '../Diagnosis_result_advise/Diagnosis_result_advise';
import DiagnosisResultCondition from '../Diagnosis_result_condition/Diagnosis_result_condition';
import DiagnosisResultType from '../Diagnosis_result_type/Diagnosis_result_type';
import styles from './Diagnosis_result.module.css';



export interface IskinConditionScore {
  elasticityScore: number
  breakoutScore: number
  sensitivityScore: number
  agingScore: number
  dehydrationScore: number
  odorScore: number
  darknessScore: number
  deadSkinScore: number
  recoveryScore: number
  itchyScore: number
  baseScore: number
}

export interface IomrTypeInfo {
  skinType: string
  skinTypeSymbol: string
  oilScore: number,
  notOliScore: number,
  moistScore: number,
  dryScore: number,
  sensiScore: number,
  resisScore: number,
  desc: string
}


const DiagnosisResult = (): React.ReactElement => {

  const skinConditionScore: IskinConditionScore = {
    elasticityScore: localStorage.getItem('skinScoreResults') === null ? 0 : parseJsonFromLocal('skinScoreResults').elasticityScore,
    breakoutScore: localStorage.getItem('skinScoreResults') === null ? 0 : parseJsonFromLocal('skinScoreResults').breakoutScore,
    sensitivityScore: localStorage.getItem('skinScoreResults') === null ? 0 : parseJsonFromLocal('skinScoreResults').sensitivityScore,
    agingScore: localStorage.getItem('skinScoreResults') === null ? 0 : parseJsonFromLocal('skinScoreResults').agingScore,
    dehydrationScore: localStorage.getItem('skinScoreResults') === null ? 0 : parseJsonFromLocal('skinScoreResults').dehydrationScore,
    odorScore: localStorage.getItem('skinScoreResults') === null ? 0 : parseJsonFromLocal('skinScoreResults').odorScore,
    darknessScore: localStorage.getItem('skinScoreResults') === null ? 0 : parseJsonFromLocal('skinScoreResults').darknessScore,
    deadSkinScore: localStorage.getItem('skinScoreResults') === null ? 0 : parseJsonFromLocal('skinScoreResults').deadSkinScore,
    recoveryScore: localStorage.getItem('skinScoreResults') === null ? 0 : parseJsonFromLocal('skinScoreResults').recoveryScore,
    itchyScore: localStorage.getItem('skinScoreResults') === null ? 0 : parseJsonFromLocal('skinScoreResults').itchyScore,
    baseScore: localStorage.getItem('skinScoreResults') === null ? 0 : parseJsonFromLocal('skinScoreResults').baseScore,
  }

  const omrTypeInfo = generateOMRType(skinConditionScore.agingScore,
    skinConditionScore.dehydrationScore, skinConditionScore.sensitivityScore,
    skinConditionScore.elasticityScore, skinConditionScore.breakoutScore,
    skinConditionScore.deadSkinScore)

  return (
    <div className={styles.section}>
      <div className={styles.container}>
        <div className={styles.leftSection}>
          <DiagnosisResultType omrTypeInfo={omrTypeInfo} />
          <GoToPersonalProductBtn />
        </div>
        <div className={styles.rightSection}>
          <DiagnosisResultCondition skinConditionScore={skinConditionScore} />
          <DiagnosisResultAdvise skinConditionScore={skinConditionScore} />
        </div>
      </div>
    </div>
  )
}

export default DiagnosisResult;

export function parseJsonFromLocal(string: string) {
  const stringFromLocal = localStorage.getItem(string)
  if (typeof (stringFromLocal) === 'undefined') {
    return null
  }
  if (typeof (stringFromLocal) === 'string') {
    return JSON.parse(stringFromLocal)
  }
}

export function GoToPersonalProductBtn() {
  const navigate = useNavigate();

  const navigateToProduct = () => {
    navigate('/personal-product');
  }

  return (
    <div style={{
      backgroundColor: 'black',
      margin: '50px auto 20px auto',
      padding: '20px 0',
      maxWidth: '550px',
      color: 'white',
      fontWeight: '500',
      fontSize: '18px',
      cursor: 'pointer'
    }} onClick={navigateToProduct}>
      맞춤형 화장품 확인하기
    </div>
  )
}