import React from 'react';
import styles from './Navbar.module.css';
import { useNavigate } from 'react-router-dom';
import SubNavbar from '../Sub_navbar/Sub_navbar';

const Navbar = () => {

	const navigate = useNavigate();
	const onNavigate = (url: string): void => {
		navigate(url)
	}
	const handleLogin = () => {
		if (localStorage.getItem('userInfo')) {
			navigate('/');
		} else {
			navigate('/login');
		}
	}


	const handleLogout = () => {
		localStorage.removeItem('accessToken');
		localStorage.removeItem('userInfo');
		navigate('/');
	}

	return (
		<>
			<nav className={styles.section}>
				<div className={styles.container}>
					<img
						onClick={() => onNavigate('/')}
						src='/logo_white.png'
						alt='actibiome logo'
						className={styles.logo} />
					<div className={styles.list}>
						<h4 onClick={() => onNavigate('url')}>회사 소개</h4>
						<h4 onClick={() => onNavigate('url')}>스토어</h4>
						<h4 onClick={() => onNavigate('diagnosis')}>진단 홈</h4>
						{localStorage.getItem('userInfo') &&
							<h4 onClick={() => onNavigate('url')}>진단기록</h4>
						}
						<div className={styles.btnContainer}>
							<button className={styles.userBtn} onClick={handleLogin}>{localStorage.getItem('userInfo') ? '마이페이지' : '로그인'}</button>
							{localStorage.getItem('userInfo') && <button className={styles.logoutBtn} onClick={handleLogout}>로그아웃</button>}
						</div>
					</div>
				</div>
			</nav>
			<div className={styles.subnav}>
				<SubNavbar />
			</div>
		</>
	)
}

export default Navbar;