import React from 'react';
import { IprevNextState } from '../../pages/Diagnosis_main/Diagnosis_main';
import styles from './Diagnosis_pre_next_btn.module.css';


interface IDiagnosisPreNextBtn {
  preNextState: IprevNextState,
  setDiagnosisState: React.Dispatch<React.SetStateAction<string>>
}


const DiagnosisPreNextBtn = (
  { preNextState, setDiagnosisState }: IDiagnosisPreNextBtn
) => {


  const handlePrev = () => {
    setDiagnosisState(preNextState.prev);
  }

  const handleNext = () => {
    setDiagnosisState(preNextState.next);
  }

  return (
    <div className={styles.section}>
      <button className={styles.prevBtn} onClick={handlePrev}>
        이전
      </button>
      <button className={styles.nextBtn} onClick={handleNext}>
        다음
      </button>
    </div>
  )
}

export default DiagnosisPreNextBtn;