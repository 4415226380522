import React from 'react';
import DiagnosisResult from '../../components/Diagnosis_result/Diagnosis_result';
import styles from './Diagnosis_end.module.css';


const DiagnosisEnd = (): React.ReactElement => {

  return (
    <section className={styles.section}>
      <DiagnosisResult />
    </section>
  )
}
export default DiagnosisEnd;