import React from 'react';
import styles from './Personal_product_base.module.css';


export interface IprescribedBaseItem {
  iconURL: string
  engName: string
  name: string
  desc: string
}

interface IbaseItem {
  prescribedBaseItem: IprescribedBaseItem
}

const PersonalProductBase = (
  { prescribedBaseItem }: IbaseItem
) => {

  const baseInfo = ['지성 앰플', '건성 앰플', '민감성 앰플', '복합성 앰플', '지성 토너', '건성 토너', '민감성 토너', '복합성 토너']


  return (
    <div className={styles.section}>
      <h3 className={styles.mainTitle}>베이스</h3>
      <div className={styles.btnContainer}>
        {baseInfo.map((base, index) => <div
          key={index} className={`${styles.btn} ${prescribedBaseItem.name === base ? styles.selected : ''}`}>{base}</div>)}
      </div>
      <div className={styles.infoBox}>
        {baseInfo.map((base, index) => {
          if (prescribedBaseItem.name === base) {
            return <p key={index} className={styles.desc}>
              <img className={styles.baseIcon} src={prescribedBaseItem.iconURL} alt='base' />{prescribedBaseItem.desc}</p>
          }
          return null
        })}
      </div>

    </div>
  )
}

export default PersonalProductBase;