import React from 'react';
import CartPurchaseBtn from '../Cart_purchase_Btn/Cart_purchase_Btn';
import styles from './Product_basicInfo.module.css';

interface IshotInfo {
  nameFirst: string;
  nameSecond: string;
  nameThird: string;
  nameFourth: string;
  nameFifth: string;
  shotNameFirst: string;
  shotNameSecond: string;
  shotNameThird: string;
  shotNameFourth: string;
  shotNameFifth: string;
  shotColorFirst: string;
  shotColorSecond: string;
  shotColorThird: string;
  shotColorFourth: string;
  shotColorFifth: string;
}

const ProductBasicInfo = (
  { shotNameFirst, shotNameSecond, shotNameThird, shotNameFourth, shotNameFifth,
    shotColorFirst, shotColorSecond, shotColorThird, shotColorFourth, shotColorFifth,
    nameFirst, nameSecond, nameThird, nameFourth, nameFifth,
  }: IshotInfo
): React.ReactElement => {

  return (
    <div className={styles.section}>
      <div className={styles.container}>
        <img src='/images/sampleProduct/sample3.png' alt='product'
          className={styles.product}></img>
        <h1 className={styles.name}> 맞춤형 스킨케어 앰플/토너</h1>
        <div className={styles.infoBox}>
          <div className={styles.leftInfo}>
            <div className={styles.leftInfoBox}>
              <h4 className={styles.volume}>50ml</h4>
              <h3 className={styles.price}>50,000원</h3>
            </div>
          </div>
          <div className={styles.rightInfo}>
            <span className={styles.btn}>−</span>
            <span className={styles.quantity}>1</span>
            <span className={styles.btn}>+</span>
          </div>
        </div>
        <div className={styles.shotCnt}>
          <Shot name={nameFirst} shotName={shotNameFirst} shotColor={shotColorFirst} level='3' />
          <Shot name={nameSecond} shotName={shotNameSecond} shotColor={shotColorSecond} level='3' />
          <Shot name={nameThird} shotName={shotNameThird} shotColor={shotColorThird} level='2' />
          <Shot name={nameFourth} shotName={shotNameFourth} shotColor={shotColorFourth} level='2' />
          <Shot name={nameFifth} shotName={shotNameFifth} shotColor={shotColorFifth} level='1' />
        </div>
        <CartPurchaseBtn />
      </div>
    </div>
  )
}

export default ProductBasicInfo;

function Shot(
  { shotColor, shotName, name, level }: { shotColor: string, shotName: string, name: string, level: string }
) {

  return (
    <div className={styles.shotInfo}>
      <div className={styles.shot}>
        <div className={styles.shotColor}
          style={{ backgroundColor: `${shotColor}` }}>
          <img src='/images/icons/Shot.png' alt='shot' />
        </div>
        <div className={styles.shotItem}>
          <div className={styles.shotName}>{shotName} - Lv.{level}</div>
          <div className={styles.shotDesc}>{name}</div>
        </div>
      </div>
      <div className={styles.shotBtn}>자세히</div>
    </div>
  )
}
