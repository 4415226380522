import React from 'react';
import DiagnosisInfo from '../../components/Diagnosis_Info/Diagnosis_Info';

const DiagnosisHome = (): React.ReactElement => {

  return (
    <div>
      <DiagnosisInfo />
    </div>
  )
}

export default DiagnosisHome;