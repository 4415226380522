

export const prescribeBundleItems = (
  elasticityScore: number,
  breakoutScore: number,
  sensitivityScore : number,
  agingScore: number,
  dehydrationScore: number,
  odorScore: number,
  darknessScore: number,
  deadSkinScore: number,
  recoveryScore: number,
  itchyScore: number,
) => {

  const biomes = [
    { score : agingScore,
      iconURL: '/images/skinConditions/aging_B.png',
      engName: 'aging',
      name: '노화케어',
      shotName : '샤이닝옴(Shiningome)',
      shotColor : '#FF6565',
      desc: `광노화 관리를 위해 부스터 샷 샤이닝옴으로 처방하였습니다. 
      샤이닝 옴은 햇볕에서 나오는 자외선 차단 및 우주 방사선을 차단하고 열악한 환경에서도 살아남는 강인함으로 피부에 방어막을 형성 시켜주는 바이옴 입니다. 
      `
    },
    { score : breakoutScore,
      iconURL: '/images/skinConditions/breakout_B.png',
      engName: 'breakout',
      name: '트러블케어',
      shotName : '리페옴(Repeome)',
      shotColor : '#CB84EA',
      desc:`얼굴 진정을 관리를 위해 부스터 샷 리페옴으로 처방하였습니다. 
      리페 옴은 피부에 중요한 세라마이드 피부 환경을 만들어 피부 진정에 최적회된 마이크로바이옴입니다.
      `
    },
    { score : darknessScore,
      iconURL: '/images/skinConditions/darkness_B.png',
      engName: 'darkness',
      name: '색소케어',
      shotName : '브라이티옴(Brightiome)',
      shotColor : '#657DFF',
      desc: `미백 관리를 위해 부스터 샷 브라이티옴을 처방하였습니다. 
      브라이티 옴은 정상 피부 상재균으로 피부의 접합부위나 수분함량이 상대적으로 높은 부위에서 발견되며, 피부가 하얀 특성을 보이고, 멜라닌 생성을 억제 시켜 미백에 탁월한 바이옴 입니다.
      `
    },
    { score : deadSkinScore,
      iconURL: '/images/skinConditions/deadSkin_B.png',
      engName: 'deadSkin',
      name: '각질케어',
      shotName : '실키옴(Silkiome)',
      shotColor : '#5EC9C9',
      desc: `피부 각질을 위해 부스터 샷 실키옴을 처방하였습니다. 
      실키 옴은 염이 많은 환경에서도 외부 삼투압에서도 견디고 생존하는 극한 미생물로 이는 단백질, 지질 분해하는데 특화된 성질을 가지고 있으며 각질형성 세포에 지질 저해 시키는 바이옴 입니다. 
      `
    },
    { score : dehydrationScore,
      iconURL: '/images/skinConditions/dehydration_B.png',
      engName: 'dehydration',
      name: '수분케어',
      shotName : '아쿠옴(Aquome)',
      shotColor : '#90E59A',
      desc: `보습관리를 위해 부스터 샷 아쿠옴을 처방하였습니다. 
      아쿠 옴은 히알루론산 과 수분채널 발현을 유의적으로 증가시키고 보습에 특화된 물질을 생산하는 바이옴 입니다. 
      `
    },
    { score : elasticityScore,
      iconURL: '/images/skinConditions/elasticity_B.png',
      engName: 'elasticity',
      name: '탄력케어',
      shotName : '엘라미옴(Elameome)',
      shotColor : '#FAE382',
      desc: `탄력 관리를 위해 부스터 샷 엘라미옴으로 처방하였습니다. 엘라미 옴은 20’s Age Recovery Biom으로 20대 때의 건강한 피부환경으로 되돌려주어 피부탄력에 최적화된 스킨 마이크로 바이옴입니다. 
      `
    },
    { score : itchyScore,
      iconURL: '/images/skinConditions/itchy_B.png',
      engName: 'itchy',
      name: '가려움케어',
      shotName : '더마옴(Dermaome)',
      shotColor : '#FF956E',
      desc: `가려움 완화를 위해 부스터 샷 더마옴을 처방하였습니다. 더마 옴은 건강한 어린아이의 스킨 마이크로 바이옴을 닮은 새로운 미생물로, 이중발효 기술을 적용하여 완성된 바이옴으로 강력한 가려움완화에 도움을 주는 스킨케어 솔루션 바이옴입니다.
      `
    },
    { score : odorScore,
      iconURL: '/images/skinConditions/odor_B.png',
      engName: 'odor',
      name: '체취케어',
      shotName : '퍼퓨옴(Perfuome)',
      shotColor : '#906D5E',
      desc: `체취 개선을 위해 부스터 샷 퍼퓨옴을 처방하였습니다. 
      퍼퓨 옴은 벌노랑이 종자를 아로마 에이징 공법을 이용하여 개발한 바이오 소재로 액취가 나는 바이옴을 변화시켜 체취를 개선하는데 탁월한 바이옴입니다. 
      `
    },
    { score : recoveryScore,
      iconURL: '/images/skinConditions/recovery_B.png',
      engName: 'recovery',
      name: '피부재생',
      shotName : '리바이티옴(Revitiome)',
      shotColor : '#299068',
      desc: `피부 재생을 위해 부스터 샷 리바이티옴으로 처방하였습니다. 
      리바이티 옴은 피부 재생 관리를 위해 되도록 자외선노출을 피하시고 일정한 시간에 수면과 충분한 수면시간이 매우 중요합니다.그리고 석류, 브로콜리, 감자, 블루 베리 등 과일 야채를 자주 섭취해주세요.
      `
    },
    { score : sensitivityScore,
      iconURL: '/images/skinConditions/sensitivity_B.png',
      engName: 'sensitivity',
      name: '피부진정',
      shotName : '리페옴(Repeome)',
      shotColor : '#F8B4B4',
      desc: `얼굴 진정을 관리를 위해 부스터 샷 리페옴으로 처방하였습니다. 
      리페 옴은 피부에 중요한 세라마이드 피부 환경을 만들어 피부 진정에 최적회된 마이크로바이옴입니다. 
      `
    },
  ]

  const sortedBiomes = sortObjectsByScore(biomes);
  return sortedBiomes.slice(0,5);
}


interface IsortObject {
    iconURL: string;
    score: number;
    engName : string;
    name : string;
    shotName : string;
    shotColor : string;
    desc : string;
}

function sortObjectsByScore(objects: IsortObject[]): IsortObject[] {
  return objects.sort((a, b) => a.score - b.score);
}



export const prescribeBaseItems = (type: string, baseScore : number) => {

  if(type.includes('O') && baseScore >= 50){
    return (
      {
        iconURL: '/images/skinTypes/Oily_base.png',
        engName : 'oily_ample',
        name: '지성 앰플',
        desc: '적당한 점도를 가진 베이스로 부드러운 발림성을 자랑합니다. 피부에 필요한 수분을 적절히 공급하고 유지하기에 적합하여 지성피부에 적합합니다.'
      }
    )
  }

  if(type.includes('D')&& baseScore >= 50){
    return (
      {
        iconURL: '/images/skinTypes/Dry_base.png',
        engName : 'dry_ample',
        name: '건성 앰플',
        desc: '적당한 점도를 가진 베이스로 부드러운 발림성을 자랑합니다. 피부에 필요한 수분을 적절히 공급하고 유지하기에 적합하여 지성피부에 적합합니다.'
      }
    )
  }

    if(type.includes('S') && baseScore >= 50){
    return (
      {
        iconURL: '/images/skinTypes/Sensitivity_base.png',
        engName : 'sensitiviy_ample',
        name: '민감성 앰플',
        desc: '적당한 점도를 가진 베이스로 부드러운 발림성을 자랑합니다. 피부에 필요한 수분을 적절히 공급하고 유지하기에 적합하여 지성피부에 적합합니다.'
      }
    )
  }

  if(type.includes('OD') && baseScore >= 50){
    return (
      {
        iconURL: '/images/skinTypes/Comb_base.png',
        engName : 'comb_ample',
        name: '복합성 앰플',
        desc: '적당한 점도를 가진 베이스로 부드러운 발림성을 자랑합니다. 피부에 필요한 수분을 적절히 공급하고 유지하기에 적합하여 지성피부에 적합합니다.'
      }
    )
  }

  if(type.includes('O') && baseScore < 50){
    return (
      {
        iconURL: '/images/skinTypes/Oily_base.png',
        engName : 'oily_toner',
        name: '지성 토너',
        desc: '적당한 점도를 가진 베이스로 부드러운 발림성을 자랑합니다. 피부에 필요한 수분을 적절히 공급하고 유지하기에 적합하여 지성피부에 적합합니다.'
      }
    )
  }
  
  if(type.includes('D')&& baseScore < 50){
    return (
      {
        iconURL: '/images/skinTypes/Dry_base.png',
        engName : 'dry_toner',
        name: '건성 토너',
        desc: '적당한 점도를 가진 베이스로 부드러운 발림성을 자랑합니다. 피부에 필요한 수분을 적절히 공급하고 유지하기에 적합하여 지성피부에 적합합니다.'
      }
    )
  }

    if(type.includes('S') && baseScore < 50){
    return (
      {
        iconURL: '/images/skinTypes/Sensitivity_base.png',
        engName : 'sensitivity_toner',
        name: '민감성 토너',
        desc: '적당한 점도를 가진 베이스로 부드러운 발림성을 자랑합니다. 피부에 필요한 수분을 적절히 공급하고 유지하기에 적합하여 지성피부에 적합합니다.'
      }
    )
  }

  if(type.includes('OD') && baseScore < 50){
    return (
      {
        iconURL: '/images/skinTypes/Comb_base.png',
        engName : 'comb_toner',
        name: '복합성 토너',
        desc: '적당한 점도를 가진 베이스로 부드러운 발림성을 자랑합니다. 피부에 필요한 수분을 적절히 공급하고 유지하기에 적합하여 지성피부에 적합합니다.'
      }
    )
  }

  return {
    iconURL: '',
    engName : 'Error',
    name: 'Error',
    desc: 'Error'
  }

}



