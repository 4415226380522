import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Home_main2.module.css';

const HomeMain2 = () => {

  const navigate = useNavigate();


  return (
    <div className={styles.section}>
      <div className={styles.sectionCnt}>
        <div className={styles.titleCnt}>
          <h1 className={styles.mainTitleLighter}>60억가지 맞춤형 화장품</h1>
          <h1 className={styles.mainTitleBold}>1억가지 성분조합</h1>
          <button className={styles.mainBtn} onClick={() => navigate('/diagnosis')}>맞춤형 화장품 처방받기</button>
        </div>
        <div className={styles.subTitle}><span style={{ opacity: '0.5' }}>나만의</span> 스킨케어 센터</div>

        <div className={styles.bannerCnt}>
          <div className={styles.bannerBtns}>
            <div className={styles.bannerBtn} style={{ backgroundColor: '#ECEDEA' }}>
              <img className={styles.bannerIcon} src='/images/icons/CheckSquare.png' alt='bannerIcon' />
              <div className={styles.bannerBtnInfo} >
                <div className={styles.leftBtnInfo}>
                  <p className={styles.btnTitle}>화장품 큐레이션</p>
                  <p className={styles.btnDesc}>전성분으로 효능 분석</p>
                </div>
                <div className={styles.rightBtn}>3초</div>
              </div>
            </div>

            <div className={styles.bannerBtn} style={{ backgroundColor: '#FCE3E0' }}>
              <img className={styles.bannerIcon} src='/images/icons/Robot.png' alt='bannerIcon' />
              <div className={styles.bannerBtnInfo} >
                <div className={styles.leftBtnInfo}>
                  <p className={styles.btnTitle}>화장품 궁합분석</p>
                  <p className={styles.btnDesc}>내 화장품과 궁합 확인하기</p>
                </div>
                <div className={styles.rightBtn}>1분</div>
              </div>
            </div>
          </div>
          <div className={styles.bannerBtns}>
            <div className={styles.bannerBtn} style={{ backgroundColor: '#DDEEF6' }}>
              <img className={styles.bannerIcon} src='/images/icons/UserFocus.png' alt='bannerIcon' />
              <div className={styles.bannerBtnInfo} >
                <div className={styles.leftBtnInfo}>
                  <p className={styles.btnTitle}>맞춤형 화장품 </p>
                  <p className={styles.btnDesc}>피부고민으로 제품 추천받기</p>
                </div>
                <div className={styles.rightBtn}>1분</div>
              </div>
            </div>
            <div className={styles.bannerBtn} style={{ backgroundColor: '#EFE1D6' }}>
              <img className={styles.bannerIcon} src='/images/icons/Smiley2.png' alt='bannerIcon' />
              <div className={styles.bannerBtnInfo} >
                <div className={styles.leftBtnInfo}>
                  <p className={styles.btnTitle}>유전자 분석</p>
                  <p className={styles.btnDesc}>유전자로 피부 분석받기</p>
                </div>
                <div className={styles.rightBtn}>5일</div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeMain2;