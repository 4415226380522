import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './pages/Home/Home';
import DiagnosisHome from './pages/Diagnosis_home/Diagnosis_home';
import DiagnosisMain from './pages/Diagnosis_main/Diagnosis_main';
import DiagnosisEnd from './pages/Diagnosis_end/Diagnosis_end';
import PersonalProduct from './pages/Personal_product_home/Personal_product_home';
import Login from './pages/Login/Login';
import Order from './pages/Order/Order';
import Cart from './pages/Cart/Cart';



const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <h1>페이지 준비중</h1>,
    children: [
      { index: true, element: <Home /> },
      { path: 'home', element: <Home /> },
      { path: 'login', element: <Login /> },
      { path: 'login/*', element: <Home /> },
      { path: 'diagnosis', element: <DiagnosisHome /> },
      { path: 'diagnosis-main', element: <DiagnosisMain /> },
      { path: 'diagnosis-end', element: <DiagnosisEnd /> },
      { path: 'personal-product', element: <PersonalProduct /> },
      { path: 'order', element: <Order /> },
      { path: 'cart', element: <Cart /> },
    ]
  }
])

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
