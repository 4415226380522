import React from 'react';
import { generateOMRType } from '../../api/diagnosis_omrType';
import { prescribeBaseItems, prescribeBundleItems } from '../../api/prescribe_product';
import CartPurchaseBtn from '../Cart_purchase_Btn/Cart_purchase_Btn';
import { IskinConditionScore, parseJsonFromLocal } from '../Diagnosis_result/Diagnosis_result';
import PersonalProductBase from '../Personal_product_base/Personal_product_base';
import PersonalProductBundleItem from '../Personal_product_bundleItem/Personal_product_bundleItem';
import PersonalProductFigureBoxInfo from '../Personal_product_figureBoxInfo/Personal_product_figureBoxInfo';
import ProductBasicInfo from '../Product_basicInfo/Product_basicInfo';
import styles from './Personal_product.module.css';

export interface IfigureBoxInfoTheme {
  name: string;
  bgColor: string;
  fontM: string;
  fontS: string;
  percent: string;
}


const PersonalProduct = (): React.ReactElement => {

  const FigureBoxInfoThemes = {
    base: { name: '베이스', bgColor: "#808080", fontM: '#FFF', fontS: '#FFFF', percent: '15%' },
    main_bundle: { name: '메인효능', bgColor: "#B2B2B2", fontM: '#FFFF', fontS: '#FFFF', percent: '50%' },
    add_bundle: { name: '추가효능', bgColor: "#D9D9D9", fontM: '#000', fontS: '#808080', percent: '30%' },
    sub_bundle: { name: '서브효능', bgColor: "#F2F2F2", fontM: '#000', fontS: '#808080', percent: '5%' },
  }

  const skinConditionScore: IskinConditionScore = {
    elasticityScore: localStorage.getItem('skinScoreResults') === null ? 0 : parseJsonFromLocal('skinScoreResults').elasticityScore,
    breakoutScore: localStorage.getItem('skinScoreResults') === null ? 0 : parseJsonFromLocal('skinScoreResults').breakoutScore,
    sensitivityScore: localStorage.getItem('skinScoreResults') === null ? 0 : parseJsonFromLocal('skinScoreResults').sensitivityScore,
    agingScore: localStorage.getItem('skinScoreResults') === null ? 0 : parseJsonFromLocal('skinScoreResults').agingScore,
    dehydrationScore: localStorage.getItem('skinScoreResults') === null ? 0 : parseJsonFromLocal('skinScoreResults').dehydrationScore,
    odorScore: localStorage.getItem('skinScoreResults') === null ? 0 : parseJsonFromLocal('skinScoreResults').odorScore,
    darknessScore: localStorage.getItem('skinScoreResults') === null ? 0 : parseJsonFromLocal('skinScoreResults').darknessScore,
    deadSkinScore: localStorage.getItem('skinScoreResults') === null ? 0 : parseJsonFromLocal('skinScoreResults').deadSkinScore,
    recoveryScore: localStorage.getItem('skinScoreResults') === null ? 0 : parseJsonFromLocal('skinScoreResults').recoveryScore,
    itchyScore: localStorage.getItem('skinScoreResults') === null ? 0 : parseJsonFromLocal('skinScoreResults').itchyScore,
    baseScore: localStorage.getItem('skinScoreResults') === null ? 0 : parseJsonFromLocal('skinScoreResults').baseScore,
  }

  const skinTypeSymbol = generateOMRType(skinConditionScore.agingScore,
    skinConditionScore.dehydrationScore, skinConditionScore.sensitivityScore,
    skinConditionScore.elasticityScore, skinConditionScore.breakoutScore,
    skinConditionScore.deadSkinScore).skinTypeSymbol

  const prescribedBaseItem = prescribeBaseItems(skinTypeSymbol, skinConditionScore.baseScore);

  const prescribedBundleItems = prescribeBundleItems(
    skinConditionScore.elasticityScore,
    skinConditionScore.breakoutScore,
    skinConditionScore.sensitivityScore,
    skinConditionScore.agingScore,
    skinConditionScore.dehydrationScore,
    skinConditionScore.odorScore,
    skinConditionScore.darknessScore,
    skinConditionScore.deadSkinScore,
    skinConditionScore.recoveryScore,
    skinConditionScore.itchyScore);

  return (
    <div className={styles.section}>
      <ProductBasicInfo
        nameFirst={prescribedBundleItems[0].name}
        nameSecond={prescribedBundleItems[1].name}
        nameThird={prescribedBundleItems[2].name}
        nameFourth={prescribedBundleItems[3].name}
        nameFifth={prescribedBundleItems[4].name}
        shotNameFirst={prescribedBundleItems[0].shotName}
        shotNameSecond={prescribedBundleItems[1].shotName}
        shotNameThird={prescribedBundleItems[2].shotName}
        shotNameFourth={prescribedBundleItems[3].shotName}
        shotNameFifth={prescribedBundleItems[4].shotName}
        shotColorFirst={prescribedBundleItems[0].shotColor}
        shotColorSecond={prescribedBundleItems[1].shotColor}
        shotColorThird={prescribedBundleItems[2].shotColor}
        shotColorFourth={prescribedBundleItems[3].shotColor}
        shotColorFifth={prescribedBundleItems[4].shotColor}
      />
      <div className={styles.detailInfoCnt}>
        <div className={styles.figureCnt}>
          <h2 className={styles.mainTitle}>홍길동님을 위한
          <br /><span style={{ fontWeight: '800' }}>화장품 처방 결과에요</span></h2>
          <div className={styles.figureBox}>
            <img
              className={styles.figureImg}
              src='/images/icons/Product.png' alt='figure'></img>
            <div className={styles.figureBoxInfo}>
              <PersonalProductFigureBoxInfo
                FigureBoxInfoTheme={FigureBoxInfoThemes.sub_bundle}
                nameFirst={prescribedBundleItems[4].name} />
              <PersonalProductFigureBoxInfo
                FigureBoxInfoTheme={FigureBoxInfoThemes.add_bundle}
                nameFirst={prescribedBundleItems[2].name}
                nameSecond={prescribedBundleItems[3].name} />
              <PersonalProductFigureBoxInfo
                FigureBoxInfoTheme={FigureBoxInfoThemes.main_bundle}
                nameFirst={prescribedBundleItems[0].name}
                nameSecond={prescribedBundleItems[1].name} />
              <PersonalProductFigureBoxInfo
                FigureBoxInfoTheme={FigureBoxInfoThemes.base}
                nameFirst={prescribedBaseItem.name} />
            </div>
          </div>
        </div>
        <h2 className={styles.mainTitle}><span style={{ fontWeight: '900' }}>이런이유로</span>
          <br />처방해드렸어요</h2>
        <PersonalProductBase
          prescribedBaseItem={prescribedBaseItem} />
        <PersonalProductBundleItem category='메인 효능'
          prescribedBundleItemFirst={prescribedBundleItems[0]}
          prescribedBundleItemSecond={prescribedBundleItems[1]} />
        <PersonalProductBundleItem category='서브 효능'
          prescribedBundleItemFirst={prescribedBundleItems[2]}
          prescribedBundleItemSecond={prescribedBundleItems[3]} />
        <PersonalProductBundleItem category='추가 효능'
          prescribedBundleItemFirst={prescribedBundleItems[4]} />
        <CartPurchaseBtn />
      </div>
    </div>
  )
}

export default PersonalProduct;
